import { useCallback, useEffect, useState } from 'react';
import { User } from 'models/User';
import api from 'api';
import { toast } from 'react-toastify';
import utils from 'utils';

const useAssociate = (
  id: string,
  admin = false,
): {
  associate?: User;
  loading?: boolean;
} => {
  const [loading, setLoading] = useState(true);
  const [associate, setAssociate] = useState<User>(null);

  const getAssociate = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        const { data } = await api.admins.associates.getAssociate(id);

        setAssociate(data);
      }
    } catch (err) {
      toast.error(utils.handleResponseError(err));
    } finally {
      setLoading(false);
    }
  }, [admin, id]);

  useEffect(() => {
    getAssociate();
  }, [getAssociate]);

  return {
    associate,
    loading,
  };
};

export default useAssociate;
