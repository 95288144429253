export type Template = {
  html: string;
  name: string;
};

export type TemplatePagination = {
  next: string;
  prev: string;
  items: Array<Template>;
};

export type TemplateForm = {
  attachment: Array<any>;
  selectedTemplate: string;
  templates: Array<Template>;
  scheduleDate: string;
  scheduleTime: string;
  isScheduled: boolean;
};

export const templatesEnum = {
  1: 'SBA CA Aged Order Cancel Item Sub',
  2: 'SBA CA Aged Order Cancel No Stock',
  4: 'SBA CA Declined Credit Card Transaction',
  8: 'SBA CA MR Request from FC',
  16: 'SBA CA Return Confirmation Adhoc',
};
