import React, { useContext } from 'react';
import classNames from 'classnames';
import { Loader } from 'ncoded-component-library';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import avatarImageUrl from '/assets/images/avatar.png';
import { useTranslation } from 'react-i18next';

import './Avatar.styles.scss';
import './Avatar.styles.responsive.scss';

type AvatarProps = {
  className?: string;
  onClick?: () => void;
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const { className, onClick } = props;

  const classes = classNames('sme-avatar', className);

  const { currentUser } = useContext(CurrentUserContext);

  const { t } = useTranslation();

  return (
    <div className={classes} onClick={onClick}>
      {currentUser ? (
        <p className="sme-avatar__avatar-info">
          <span>{`${currentUser.firstName} ${currentUser.lastName},`}</span>
          <span>{t(`Positions.${currentUser?.position as string}`)}</span>
        </p>
      ) : (
        <Loader color="#cc0000" inline />
      )}
      <img src={avatarImageUrl} alt="avatar" />
    </div>
  );
};

export default Avatar;
