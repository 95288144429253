import { Template } from 'models/Templates';
import { useCallback, useState } from 'react';

export default function useStyleWrapTemplates(tempaltes: Array<Template>) {
  return tempaltes.map(({ html, name }) => {
    const newData = html.split('<style>');

    newData[1] = `.template {\n${newData[1]}`;
    newData[2] = `.template {\n${newData[2]}`;

    const newData2 = newData.join('<style>').split('</style>');

    newData2[1] = `${newData2[1]}\n}\n`;
    newData2[2] = `${newData2[2]}\n}\n`;

    const newData3 = newData2.join('</style>').split(`class="root-container"`);

    newData3[1] = ` template"${newData3[1]}`;

    return {
      name,
      html: newData3.join(`class="root-container`),
    };
  });
}
