import React, { useRef, useState } from 'react';
import Table from 'components/Table';
import { useTranslation } from 'react-i18next';
import Modal, { ModalRef } from 'components/Modal';
import ManageAdminsForm from './components/ManageAdminsForm';
import useManageAdminsHandlers from './hooks/useManageAdminsHandlers';

import './ManageAdmins.styles.scss';

const ManageAdmins: React.FC = () => {
  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>();

  const [associateId, setAssociateId] = useState<string>('');

  const {
    addAssociate,
    normalizedAdmins,
    fetching,
    loading,
    changeParams,
    pagination,
  } = useManageAdminsHandlers({ modalRef, setAssociateId });

  const labels = [
    { label: t('General.firstName'), key: 'firstName' },
    { label: t('General.lastName'), key: 'lastName' },
    { label: t('General.associateId'), key: 'associateId' },
    { label: t('General.position'), key: 'position' },
  ];

  return (
    <>
      <Modal
        focusableElements="div"
        className="manage-admins-modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-associate"
        onClose={() => {
          setAssociateId('');
        }}
        onX={() => {
          setAssociateId('');
          modalRef.current.close();
        }}
        title={t('newAdmin')}
      >
        <ManageAdminsForm
          inProgress={loading}
          onSubmit={(values) => addAssociate(values)}
          handleCancel={() => {
            setAssociateId('');
            modalRef.current.close();
          }}
          associateId={associateId}
        />
      </Modal>
      <Table
        fetching={fetching}
        title={t('admins')}
        labels={labels.map(({ label }) => label)}
        elementProps={labels.map(({ key }) => key)}
        contentArray={normalizedAdmins}
        buttonText={t('addAdmin')}
        onAddButtonClick={() => modalRef.current.open()}
        changeParams={changeParams}
        pagination={pagination}
      />
    </>
  );
};

export default ManageAdmins;
