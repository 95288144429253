import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import StepFormContext from './StepForm.context';

type StepFormProps = {
  children: React.ReactNode;
  locations: Array<string>;
};

const StepForm: React.FC<StepFormProps> = (props) => {
  const { children, locations } = props;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [lastCompleteStep, setLastCompleteStep] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();

  const goToNextStep = () => {
    setCurrentStep((prevStep) => {
      setLastCompleteStep((prevLastStep) =>
        Math.max(prevLastStep, prevStep + 1),
      );

      navigate(locations[prevStep + 1]);

      return prevStep + 1;
    });
  };

  const goToPrevStep = () => {
    setCurrentStep((prevStep) => {
      navigate(locations[prevStep - 1]);

      return prevStep - 1;
    });
  };

  const resetForm = useCallback(() => {
    setCurrentStep(0);
    setLastCompleteStep(0);
    navigate(locations[0]);
  }, []);

  // Redirect user to last completed step
  useEffect(() => {
    const locationPage = locations.indexOf(
      location.pathname.split('user-dashboard/')[1],
    );

    setCurrentStep(locationPage);

    if (locationPage > lastCompleteStep) {
      navigate(locations[lastCompleteStep]);
    }
  }, [location, lastCompleteStep]);

  return (
    <StepFormContext.Provider
      value={{
        currentStep,
        lastCompleteStep,
        goToNextStep,
        goToPrevStep,
        resetForm,
      }}
    >
      {children}
    </StepFormContext.Provider>
  );
};

export default StepForm;
