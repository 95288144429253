import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import { useTranslation } from 'react-i18next';
import useHistoryState from './hooks/useHistoryState';
import DebounceInput from 'components/DebounceInput';

import './History.styles.scss';
import './History.styles.responsive.scss';

const History: React.FC = () => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState('');

  const {
    fetching,
    changeParams,
    pagination,
    labelProps,
    elementProps,
    mappedHistoryItems,
  } = useHistoryState();

  useEffect(() => {
    if (pagination) {
      changeParams({
        search: searchString,
        limit: pagination?.pageSize,
        page: 1,
      });
    }
  }, [searchString]);

  return (
    <Table
      fetching={fetching}
      title={t('history')}
      labels={labelProps}
      elementProps={elementProps}
      contentArray={mappedHistoryItems}
      changeParams={changeParams}
      pagination={pagination}
      searchString={searchString}
      headerButtons={false}
      headerElement={
        <DebounceInput
          onChange={(searchString) => setSearchString(searchString)}
          placeholder={t('General.searchByEmail')}
        />
      }
    />
  );
};

export default History;
