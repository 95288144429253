import React from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import FileInput, {
  FileInputProps,
} from 'components/FileInput/FileInput.component';

type FileInputFieldProps = FileInputProps & { name: string } & UseFieldConfig<
    File[],
    File[]
  >;

type FileInputFieldComponentProps = FileInputProps &
  FieldRenderProps<File[], HTMLElement>;

const FileInputFieldComponent: React.FC<FileInputFieldComponentProps> = (
  props,
) => {
  const { input, meta, ...restOfProps } = props;

  return <FileInput {...restOfProps} {...input} />;
};

const FileInputField: React.FC<FileInputFieldProps> = (props) => (
  <Field component={FileInputFieldComponent} {...props} />
);

export default FileInputField;
