import React from 'react';

type EyeProps = {
  className?: string;
  hide?: boolean;
} & React.SVGProps<SVGSVGElement>;

export default (props: EyeProps) => {
  const { hide, className } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16C16 16 19 10 19 10C19 10 16 4 10 4C4 4 1 10 1 10C1 10 4 16 10 16ZM10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z"
        fill={hide ? '#D9D9D7' : 'black'}
      />
      <circle cx="10" cy="10" r="2" fill={hide ? '#D9D9D7' : 'black'} />
    </svg>
  );
};
