import classNames from 'classnames';
import Header from 'components/Header';
import { Button } from 'ncoded-component-library';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import utils from 'utils';
import api from 'api';
import { toast } from 'react-toastify';
import { TemplateForm } from 'models/Templates';
import FormSidebar from './components/FormSidebar';
import { mappedTemplateKeys } from './utils';
import StepFormContext from 'providers/StepForm/StepForm.context';
import Avatar from 'components/Avatar';
import { FORM_ERROR } from 'final-form';
import SideBar from 'components/SideBar';
import LanguagePicker from 'components/LanguagePicker';

import './UserDashboard.styles.scss';
import './UserDashboard.styles.responsive.scss';

type UserDashboardProps = {
  className?: string;
};

const UserDashboard: React.FC<UserDashboardProps> = (props) => {
  const { className } = props;

  const [initialFormData, setInitialFormData] = useState<any>({
    templates: [],
  });

  const navigate = useNavigate();

  const { logout } = useContext(CurrentUserContext);

  const { goToNextStep } = useContext(StepFormContext);

  const classes = classNames('user-dashboard', className);

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      profile: t('profile'),
      logout: t('logout'),
      layoutHeader: t('UserDashboard.layoutHeader'),
      layoutText: t('UserDashboard.layoutText'),
      submitMessasge: t('UserDashboard.submitMessasge'),
    }),
    [t],
  );

  const uploadFile = useCallback(async (values: TemplateForm) => {
    try {
      const {
        attachment,
        selectedTemplate,
        scheduleDate,
        scheduleTime,
        isScheduled,
      } = values;

      const formData = new FormData();

      formData.append(
        mappedTemplateKeys[selectedTemplate as keyof typeof mappedTemplateKeys],
        attachment[0],
      );

      if (isScheduled) {
        const selectedDate = new Date(scheduleDate);
        selectedDate.setHours(+scheduleTime?.split(':')[0]);
        selectedDate.setMinutes(+scheduleTime?.split(':')[1]);
        const currentUtcTime = selectedDate.toISOString();

        formData.append('ScheduledDate', currentUtcTime);
      }

      await api.templates.uploadTemplate(formData);

      toast.success(messages.submitMessasge);

      goToNextStep();
    } catch (error: any) {
      toast.error(utils.handleResponseError(error));

      return { [FORM_ERROR]: error?.response?.data?.errors[0] };
    }
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const {
          data: { next, items: templates },
        } = await api.templates.getTemplates();

        setInitialFormData({
          next,
          templates,
        });
      } catch (err) {
        toast.error(utils.handleResponseError(err));
      }
    };

    fetchTemplates();
  }, []);

  return (
    <>
      <Header
        rightComponent={
          <>
            <LanguagePicker key="lp" />

            <Avatar onClick={() => navigate('/profile')} />

            <Button variant="outline" onClick={logout}>
              {messages.logout}
            </Button>
          </>
        }
      />
      <SideBar />
      <main className={classes}>
        <div className="user-dashboard__header">
          <h1>{messages.layoutHeader}</h1>
          <div>{messages.layoutText}</div>
        </div>
        <div className="user-dashboard__body">
          <FormSidebar />
          <Form
            onSubmit={uploadFile}
            initialValues={initialFormData}
            render={(formRenderProps) => {
              const { handleSubmit } = formRenderProps;

              return (
                <form onSubmit={handleSubmit}>
                  <Outlet />
                </form>
              );
            }}
          />
        </div>
      </main>
    </>
  );
};

export default UserDashboard;
