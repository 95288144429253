import api from 'api';
import usePaginatedItems from 'hooks/usePaginatedItems';
import { templatesEnum } from 'models/Templates';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

export default function useHistoryState() {
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);

  const isUserAdmin = currentUser.roles[0] === 'Admin';

  const associateLabels = [
    { label: t('History.fileName'), key: 'fileName' },
    { label: t('History.dateSent'), key: 'occurredOn' },
    { label: t('History.dateProcessed'), key: 'processedDate' },
  ];

  const adminLabels = [
    { label: t('History.senderEmail'), key: 'senderEmail' },
    ...associateLabels,
  ];

  const {
    items: historyItems,
    loading,
    changeParams,
    pagination,
  } = usePaginatedItems(
    isUserAdmin ? api.admins.admins.getHistory : api.associates.getHistory,
  );

  const labelProps = (isUserAdmin ? adminLabels : associateLabels).map(
    ({ label }) => label,
  );

  const elementProps = (isUserAdmin ? adminLabels : associateLabels).map(
    ({ key }) => key,
  );

  const mappedHistoryItems = useMemo(
    () =>
      historyItems.map((item) => {
        return {
          ...item,
          id: item?.occurredOn,
          template: templatesEnum[item.template as keyof typeof templatesEnum],
          occurredOn: utils.transformDateFormat(item.occurredOn),
          processedDate: item.processedDate
            ? utils.transformDateFormat(item.processedDate)
            : 'Pending',
        };
      }),
    [historyItems],
  );

  return {
    fetching: loading,
    changeParams,
    labelProps,
    elementProps,
    mappedHistoryItems,
    pagination,
  };
}
