import React, { useState } from 'react';
import classNames from 'classnames';
import { Select } from 'ncoded-component-library';
import i18n from 'i18next';

import './LanguagePicker.styles.scss';
import './LanguagePicker.styles.responsive.scss';

type LanguagePickerProps = {
  className?: string;
};

const options = [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'FR',
    value: 'fr',
  },
];

const LanguagePicker = ({ className }: LanguagePickerProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('language'),
  );

  const classes = classNames('language-picker', className);

  const changeLanguage = async (event: any) => {
    const newLanguage = event.value;

    await i18n.changeLanguage(newLanguage);

    setSelectedLanguage(newLanguage);
  };

  return (
    <Select
      className={classes}
      onChange={changeLanguage}
      options={options}
      value={selectedLanguage}
    />
  );
};

export default LanguagePicker;
