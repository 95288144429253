import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import { Button, Loader } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import Input from 'components/Input';
import BackButton from 'components/BackButton';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { ChangePasswordValue } from 'types/form.types';
import { Form } from 'react-final-form';
import api from 'api';
import { toast } from 'react-toastify';
import utils from 'utils';
import PasswordField from 'components/PasswordField';
import formValidators from 'utils/formValidators';

import './Profile.styles.scss';
import './Profile.styles.responsive.scss';

const userProps = ['firstName', 'lastName', 'email', 'associateId', 'position'];

type ProfileProps = {
  className?: string;
};

const Profile: React.FC<ProfileProps> = (props) => {
  const { className } = props;

  const classes = classNames('sme-profile', className);

  const { currentUser, logout } = useContext(CurrentUserContext);

  const [changePass, setChangePass] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const messages = useMemo(
    () => ({
      helloAdmin: t('helloAdmin', { admin: currentUser?.firstName }),
      helloAssociate: t('helloAssociate', {
        associate: currentUser?.firstName,
      }),
      subtitle: t('yourProfile'),
      firstName: t('General.firstName'),
      lastName: t('General.lastName'),
      email: t('General.email'),
      associateId: t('General.associateId'),
      position: t('General.position'),
      profileSettings: t('General.profileSettings'),
      cancel: t('General.cancel'),
      logout: t('logout'),
      currentPassword: t('currentPassword'),
      newPassword: t('newPassword'),
      changedPassword: t('Auth.ResetPassword.requestSent'),
      changePassword: t('General.changePassword'),
    }),
    [t, currentUser],
  );

  const changePassword = useCallback(
    async (values: ChangePasswordValue) => {
      try {
        await api.account.changePassword(values);

        toast.success(messages.changedPassword);
        setChangePass(false);
      } catch (err) {
        utils.toastError(err);
      }
    },
    [messages.changedPassword],
  );

  return (
    <>
      <Header
        rightComponent={
          <Button variant="outline" onClick={logout}>
            {messages.logout}
          </Button>
        }
      />
      <main className={classes}>
        <div className="sme-profile__back-title">
          <BackButton
            navigate={navigate}
            className="sme-profile__back-button"
          />
          <h1>{messages.profileSettings}</h1>
        </div>
        {currentUser ? (
          <>
            <h2>
              {currentUser.roles[0] === 'Admin'
                ? messages.helloAdmin
                : messages.helloAssociate}
            </h2>
            <div className="sme-profile__data">
              <div className="sme-profile__left">
                <img src="assets/images/avatar.png" alt="avatar" />
              </div>
              <div className="sme-profile__fields">
                {userProps.map((name) => {
                  return (
                    <Input
                      key={`${name}-key`}
                      name={name}
                      value={
                        currentUser
                          ? currentUser[
                              name as keyof typeof currentUser
                            ]?.toString()
                          : ''
                      }
                      label={messages[name as keyof typeof messages]}
                      className="sme-profile__fields__field"
                      disabled
                    />
                  );
                })}

                <Button
                  type="button"
                  onClick={() => setChangePass((prev) => !prev)}
                  variant="link"
                >
                  {changePass ? messages.cancel : messages.changePassword}
                </Button>

                {changePass && (
                  <Form
                    onSubmit={changePassword}
                    render={(formRenderProps) => {
                      const { handleSubmit, invalid, dirty, submitting } =
                        formRenderProps;

                      const submitDisabled = invalid || submitting || !dirty;

                      return (
                        <form
                          className="change-password-form"
                          onSubmit={handleSubmit}
                        >
                          <PasswordField
                            name="currentPassword"
                            label={messages.currentPassword}
                            validate={formValidators.getPasswordValidators(t)}
                            required
                            placeholder="********"
                          />
                          <PasswordField
                            name="newPassword"
                            label={messages.newPassword}
                            validate={formValidators.getPasswordValidators(t)}
                            required
                            placeholder="********"
                          />
                          <div className="change-password-form__actions">
                            <Button
                              disabled={submitDisabled}
                              type="submit"
                              variant="solid"
                            >
                              {messages.changePassword}
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <Loader color="#cc0000" inline />
        )}
      </main>
    </>
  );
};

export default Profile;
