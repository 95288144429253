import httpClient from '../../httpClient';
import { Group, GroupNames, GroupSubmitFormBody } from 'models/User';
import { BaseParams, PaginatedReponse } from 'models/Pagination';

const groupsPath = (routePath: TemplateStringsArray | string) =>
  `admins/groups${routePath}`;

const addGroup = (groupInfo: GroupSubmitFormBody) => {
  return httpClient.post<Group>(groupsPath``, groupInfo);
};

const getGroups = (params: BaseParams) => {
  return httpClient.get<PaginatedReponse<Group>>(groupsPath``, {
    params,
  });
};

const getGroupNames = (params?: BaseParams) => {
  return httpClient.get<PaginatedReponse<GroupNames>>(groupsPath`/names`, {
    params,
  });
};

const getGroup = (id: string) => {
  return httpClient.get<Group>(groupsPath(`/${id}`));
};

const updateGroup = (groupInfo: Partial<GroupSubmitFormBody>, id: string) => {
  return httpClient.patch<Group>(groupsPath(`/${id}`), groupInfo);
};

const deleteGroup = (id: string) => {
  return httpClient.delete(groupsPath(`/${id}`));
};

export default {
  addGroup,
  getGroups,
  getGroupNames,
  getGroup,
  updateGroup,
  deleteGroup,
};
