import api from 'api';
import usePaginatedItems from 'hooks/usePaginatedItems';
import { debounce } from 'lodash';
import { BaseParams } from 'models/Pagination';
import {
  GroupNames,
  ManageUsersFormBody,
  ManageUsersSubmitFormBody,
  User,
} from 'models/User';
import confirm from 'modules/confirm';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { positionOptions } from 'types/types';
import utils from 'utils';

type HookProps = {
  modalRef: React.MutableRefObject<OverlayRef>;
  setAssociateId: React.Dispatch<React.SetStateAction<string>>;
};

export default function useManageUsersHandlers(props: HookProps) {
  const { modalRef, setAssociateId } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [groupNames, setGroupNames] = useState<GroupNames[]>([]);
  const [groupsParams, setGroupsParams] = useState<BaseParams>({
    limit: 100,
    page: 1,
  });

  const {
    items: associates,
    loading: fetching,
    changeParams,
    pagination,
    refresh,
  } = usePaginatedItems(api.admins.associates.getAssociates);

  const changeGroupsParams = useCallback(
    debounce((search: string) => {
      setGroupsParams((prev) => ({ ...prev, search }));
    }, 500),
    [],
  );

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const { data } = await api.admins.groups.getGroupNames(groupsParams);
        const { items } = data;

        setGroupNames(items);
      } catch (err) {
        toast.error(utils.handleResponseError(err));
      }
    };

    fetchGroups();
  }, [groupsParams]);

  const deleteAssociate = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        await api.admins.associates.deleteAssociate(id);
        toast.success(t('successDeletingAssociate'));
      } catch (error) {
        toast.error(t('General.error'));
      } finally {
        setLoading(false);
        refresh();
      }
    },
    [t],
  );

  const handleDeleteAssociate = useCallback(
    async (values: Partial<User>) => {
      const confirmDeletion = await confirm({
        title: t('deleteAssociateTitle'),
        confirmBtnText: t('Delete'),
        content: t('deleteAssociate', {
          name: values?.firstName,
        }),
      });

      if (confirmDeletion) {
        deleteAssociate(values?.id);
      }
    },
    [deleteAssociate, t],
  );

  const addAssociate = useCallback(
    async (values: ManageUsersSubmitFormBody) => {
      try {
        setLoading(true);

        await api.admins.associates.addAssociate(values);
        toast.success(t('successAddingAssociate'));

        modalRef.current.close();
        setAssociateId('');
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      } finally {
        setLoading(false);
      }
    },
    [t],
  );

  const updateAssociate = useCallback(
    async (values: Partial<ManageUsersSubmitFormBody>, id: string) => {
      try {
        setLoading(true);

        await api.admins.associates.updateAssociate(values, id);
        toast.success(t('successUpdatingAssociate'));

        modalRef.current.close();
        setAssociateId('');
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      } finally {
        setLoading(false);
      }
    },
    [t],
  );

  const onSubmit = useCallback(
    async (values: ManageUsersFormBody, id?: string) => {
      const { selectedGroups, ...rest } = values;

      if (id) {
        updateAssociate(rest, id);
      } else {
        addAssociate(rest);
      }
    },
    [addAssociate, updateAssociate],
  );

  const normalizedUsers = associates?.map(({ position, groups, ...rest }) => ({
    ...rest,
    position: t(`Positions.${positionOptions[position].label}`) || '',
    groups:
      groups?.length > 4
        ? `${groups
            .slice(0, 4)
            .map((group) => group.name)
            .join(', ')}...`
        : groups?.map((group) => group.name)?.join(', '),
  }));

  return {
    onSubmit,
    handleDeleteAssociate,
    normalizedUsers,
    fetching,
    loading,
    groupNames,
    changeParams,
    pagination,
    changeGroupsParams,
  };
}
