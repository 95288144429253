import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import StepFormContext from 'providers/StepForm/StepForm.context';
import { useFormState } from 'react-final-form';
import Modal, { ModalRef } from 'components/Modal';
import InputField from 'components/InputField';
import CheckboxField from 'components/CheckboxField';
import Dropzone from './components/Dropzone';
import getFileUploadState from './hooks/getFileUploadState';
import PreviewTable from './components/PreviewTable';

import './FileUpload.styles.scss';
import './FileUpload.styles.responsive.scss';

type FileUploadProps = {
  className?: string;
};

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const { className } = props;

  const { goToPrevStep } = useContext(StepFormContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const modalRef = useRef<ModalRef>();

  const {
    values: { selectedTemplate, scheduleDate, isScheduled },
  } = useFormState();

  const openPreview = useCallback(() => {
    modalRef.current.open();
  }, [modalRef]);

  const {
    errors: { disableSubmit, scheduleError },
    state: { fileData, filteredFileData, currentDate, currentTime, attachment },
  } = getFileUploadState();

  const classes = classNames('upload-file', className);

  const scheduleClass = classNames('upload-file__schedule', {
    'upload-file__schedule--error': scheduleError,
  });

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      layoutHeader: t('UserDashboard.FileUpload.layoutHeader'),
      layoutText: t('UserDashboard.FileUpload.layoutText'),
      scheduleSend: t('UserDashboard.FileUpload.scheduleSend'),
      back: t('General.back'),
      preview: t('UserDashboard.FileUpload.preview'),
      submit: t('General.submit'),
    }),
    [t],
  );

  return (
    <div className={classes}>
      <div className="upload-file__header">
        <h2>{messages.layoutHeader}</h2>
        <div>
          <Trans
            i18nKey={messages.layoutText}
            values={{ selectedTemplate: selectedTemplate }}
          />
        </div>
      </div>

      <Dropzone />

      {filteredFileData && (
        <div className="upload-file__preview">
          <PreviewTable tableData={filteredFileData} />
        </div>
      )}

      <div className={scheduleClass}>
        <CheckboxField label={messages.scheduleSend} name={`isScheduled`} />
        <div className="upload-file__date-time">
          <InputField
            name="scheduleDate"
            type="date"
            disabled={!isScheduled}
            required={isScheduled}
            min={currentDate}
          />
          <InputField
            name="scheduleTime"
            type="time"
            disabled={!isScheduled}
            required={isScheduled}
            min={currentDate === scheduleDate ? currentTime : undefined}
          />
        </div>
      </div>

      <div className="upload-file__form-navigation">
        <Button variant="outline" onClick={goToPrevStep}>
          {messages.back}
        </Button>
        <Button
          variant="outline"
          onClick={openPreview}
          disabled={!fileData?.length}
        >
          {messages.preview}
        </Button>
        <Button type="submit" disabled={disableSubmit}>
          {messages.submit}
        </Button>
      </div>

      <Modal
        renderAsPortal
        focusableElements="div"
        className="upload-file-modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-associate"
        addSearchParams={false}
        keepOpenOnRefresh={false}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        onX={() => modalRef.current.close()}
        title={<div className="upload-file-modal__header">File preview</div>}
      >
        {fileData?.length > 0 && (
          <PreviewTable tableData={fileData} skipFirstColumn />
        )}
      </Modal>
    </div>
  );
};

export default FileUpload;
