import auth from './auth';
import admins from './admins';
import account from './account';
import associates from './associates';
import templates from './templates';

export default {
  auth,
  admins,
  account,
  associates,
  templates,
};
