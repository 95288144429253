import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import History from './pages/History';
import ManageAdmins from './pages/ManageAdmins';
import ManageGroups from './pages/ManageGroups';
import ManageUsers from './pages/ManageUsers';

export default [
  {
    path: 'manage-users',
    element: ManageUsers,
    authorizedRoles: ['Admin'],
  },
  {
    path: 'manage-admins',
    element: ManageAdmins,
    authorizedRoles: ['Admin'],
  },
  {
    path: 'manage-groups',
    element: ManageGroups,
    authorizedRoles: ['Admin'],
  },
  {
    path: 'history',
    element: History,
  },
  {
    index: true,
    element: <Navigate to="manage-users" replace />,
  },
] as Array<EnhancedRouteProps>;
