import React from 'react';
import { Button, Loader } from 'ncoded-component-library';
import classNames from 'classnames';
import Row, { RowProps } from '../Row/Row.component';
import { useTranslation } from 'react-i18next';
import TablePagination from 'router/subrouters/Dashboard/pages/History/components/TablePagination';
import { BaseParams, Pagination } from 'models/Pagination';

import './Table.styles.scss';

type TableProps = {
  className?: string;
  fetching?: boolean;
  title?: string;
  labels?: string[];
  contentArray?: any[];
  buttonText?: string;
  headerButtons?: boolean;
  headerElement?: JSX.Element;
  pagination?: Pagination;
  searchString?: string;
  changeParams?: (params: BaseParams) => void;
  onAddButtonClick?: () => void;
  onUploadTemplate?: () => void;
} & RowProps;

const Table: React.FC<TableProps> = (props) => {
  const {
    className,
    fetching,
    title,
    contentArray,
    labels,
    buttonText,
    onAddButtonClick,
    onUploadTemplate,
    headerElement,
    headerButtons = true,
    pagination,
    searchString,
    changeParams,
    ...rest
  } = props;

  const { t } = useTranslation();

  const classes = classNames('sme-table ', className, {
    'sme-table--with-pagination': !!pagination,
  });

  return (
    <div className={classes}>
      <>
        <div className="sme-table__header">
          <h1>{title}</h1>
          {headerElement}
          {headerButtons && (
            <div className="sme-table__header__buttons">
              {onUploadTemplate && (
                <Button
                  className="sme-table__upload-file"
                  variant="outline"
                  onClick={onUploadTemplate}
                >
                  {t('uploadProducts')}
                </Button>
              )}
              <Button
                className="sme-table__add"
                variant="outline"
                onClick={onAddButtonClick}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
        {fetching && <Loader color="#cc0000" />}

        <div className="sme-table__wrapper">
          <table>
            <thead>
              <tr className="sme-table__labels">
                {labels?.map((label, index) => (
                  <th key={index}>{label}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {contentArray?.map((el) => (
                <Row el={el} {...rest} key={el.id} />
              ))}
            </tbody>
          </table>
        </div>

        {pagination && pagination.totalCount !== 0 && (
          <TablePagination
            changeParams={changeParams}
            pagination={pagination}
            fetching={fetching}
            searchString={searchString}
          />
        )}
      </>
    </div>
  );
};

export default Table;
