import httpClient from '../httpClient';
import { ChangePasswordValue } from 'types/form.types';

const accountPath = (routePath: TemplateStringsArray | string) =>
  `account/${routePath}`;

const getCurrentUser = () => {
  return httpClient.get(accountPath`current`);
};

const changePassword = (userData: ChangePasswordValue) => {
  return httpClient.patch(accountPath`change-password`, userData);
};

export default {
  getCurrentUser,
  changePassword,
};
