import { FieldValidator } from 'final-form';
import { TFunction } from 'i18next';
import { FieldMetaState } from 'react-final-form';
import regex from 'constants/regex';

const PASSWORD_MIN_LENGTH = 8;
const { EMAIL: EMAIL_REGEX, PASSWORD: PASSWORD_REGEX } = regex;

export const MAX_ASSOCIATE_ID_CHARS = 15;

const VALID = undefined as undefined;

const getErrorFromMeta = <T>(
  meta: FieldMetaState<T>,
): { hasError: boolean; error: string } => {
  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);

  return { hasError, error };
};

const composeValidators =
  (...validators: any[]) =>
  (value: string) => {
    for (const validator of validators) {
      const error = validator(value);
      if (error) {
        return error;
      }
    }

    return VALID;
  };

const isNonEmptyString = (val: any) => {
  return typeof val === 'string' && val.trim().length > 0;
};

const isEmptyValue = (val: any) => typeof val === 'undefined' || val === null;

const required =
  <T>(message: string): FieldValidator<T> =>
  (value: T) => {
    if (isEmptyValue(value)) {
      // undefined or null values are invalid
      return message;
    }

    if (typeof value === 'string') {
      // string must be nonempty when trimmed
      return isNonEmptyString(value) ? VALID : message;
    }

    return VALID;
  };

const minChars = (chars: number, errorMessage: string) => (value: string) =>
  value && value.length >= chars ? VALID : errorMessage;

const maxChars =
  <T>(message: string, maxLength: number): FieldValidator<T> =>
  (value: any) => {
    const hasLength = value && typeof value.length === 'number';

    return hasLength && value.length <= maxLength ? VALID : message;
  };

const passwordValidation = (errorMessage: string) => (value: string) =>
  PASSWORD_REGEX.test(value) ? VALID : errorMessage;

const emailFormatValid =
  (message: string): FieldValidator<string> =>
  (value: string) => {
    return value && EMAIL_REGEX.test(value) ? VALID : message;
  };

const getEmailValidators = (
  t: TFunction,
  isRequired = true,
  requiredMsg = 'General.emailRequired',
  invalidFormatMsg = 'General.emailInvalid',
): FieldValidator<string> | ((value: string) => FieldValidator<string>) => {
  const validFormat = emailFormatValid(t(invalidFormatMsg));

  if (isRequired) {
    return composeValidators(required(t(requiredMsg)), validFormat);
  }

  return validFormat;
};

const noSpaces = (message: string) => (value: string) => {
  return /\s/.test(value) ? message : VALID;
};

const getPasswordValidators = (
  t: TFunction,
  requiredMsg = 'General.passwordRequired',
  minLengtMsg = 'General.passwordMinLength',
  passwordRequirements = 'General.passwordRequirements',
) =>
  composeValidators(
    required(t(requiredMsg)),
    minChars(
      PASSWORD_MIN_LENGTH,
      t(minLengtMsg, { minLength: PASSWORD_MIN_LENGTH }),
    ),
    passwordValidation(t(passwordRequirements)),
  );

export default {
  composeValidators,
  required,
  getEmailValidators,
  minChars,
  maxChars,
  passwordValidation,
  getErrorFromMeta,
  getPasswordValidators,
  noSpaces,
};
