import { FormApi } from 'final-form';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const MAX_FILE_SIZE = 10 * 2 ** 20;

export default function (
  fileType: string,
  formApi: React.MutableRefObject<
    FormApi<Record<string, any>, Partial<Record<string, any>>>
  >,
) {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'document/*': [fileType] },
    multiple: false,
    noClick: true,
    noKeyboard: true,
    maxSize: MAX_FILE_SIZE,
    noDragEventsBubbling: true,
    onDrop: (files) => {
      const filteredFiles: File[] = [];
      const promises: Promise<any>[] = [];

      files.forEach(async (file) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        promises.push(
          new Promise(
            (resolve, reject) =>
              (reader.onload = async (e) => {
                const { size } = file;
                if (size > MAX_FILE_SIZE) {
                  toast.error(
                    t('maxFileSize', {
                      maxFileSize: (MAX_FILE_SIZE / 2 ** 20).toFixed(0),
                    }),
                  );
                  reject();
                } else {
                  Object.assign(file, {
                    preview: e.target?.result,
                  });
                  filteredFiles.push(file);
                  resolve(file);
                }
              }),
          ),
        );

        await Promise.all(promises);
        formApi.current.batch(() => {
          formApi.current.change('attachment', files);
        });
      });
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        switch (file.errors[0].code) {
          case 'file-too-large':
            toast.error(t('fileLargerThan'));
            return;
          case 'file-invalid-type':
            toast.error(t('notificationFileType'));
            return;
        }
      });
    },
  });
  return { getRootProps, getInputProps };
}
