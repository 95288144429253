import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Form, FormProps } from 'react-final-form';
import { SubmissionErrors } from 'final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { Button, Loader } from 'ncoded-component-library';
import formValidators, { MAX_ASSOCIATE_ID_CHARS } from 'utils/formValidators';
import { ManageAdminsFormBody, Admin } from 'models/User';

import './ManageAdminsForm.styles.scss';

const { required } = formValidators;

type ManageAdminsFormProps = {
  className?: string;
  onSubmit: (
    values: ManageAdminsFormBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  handleCancel?: () => void;
  associateId?: string;
  inProgress: boolean;
} & FormProps<ManageAdminsFormBody>;

const ManageAdminsForm: React.FC<ManageAdminsFormProps> = (props) => {
  const { className, associateId, inProgress, handleCancel, onSubmit } = props;

  const classes = classNames('sme-manage-admins-form', className);
  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      firstName: t('General.firstName'),
      lastName: t('General.lastName'),
      associateId: t('General.associateId'),
      position: t('General.position'),
      email: t('General.email'),
      password: t('General.password'),
      confirmNewPassword: t('General.confirmNewPassword'),
      cancel: t('General.cancel'),
      addAdmin: t('addAdmin'),
      passwordMismatch: t('General.passwordMismatch'),
      id: t('id'),
    }),
    [t],
  );

  const passwordMatchValidator = useCallback(
    (values: ManageAdminsFormBody) => {
      const errors: Partial<ManageAdminsFormBody> = {};

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = messages.passwordMismatch;
      }

      return errors;
    },
    [messages.passwordMismatch],
  );

  const passwordValidators = useMemo(
    () => formValidators.getPasswordValidators(t),
    [t],
  );

  const validation = useMemo(
    () => ({
      associateId: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.maxChars(
          t('maxChars', { chars: MAX_ASSOCIATE_ID_CHARS }),
          MAX_ASSOCIATE_ID_CHARS,
        ),
        formValidators.noSpaces(t('noSpaces')),
      ),

      email: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.getEmailValidators(t),
      ),
    }),
    [t],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      validate={passwordMatchValidator}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, dirty } = formRenderProps;

        const submitDisabled = invalid || inProgress || !dirty;

        return (
          <>
            {inProgress && <Loader color="#cc0000" />}
            <form className={classes} onSubmit={handleSubmit}>
              <InputField
                type="text"
                name="firstName"
                label={messages.firstName}
                required
                validate={required(t('required'))}
                placeholder={messages.firstName}
                disabled={!!associateId}
              />
              <InputField
                type="text"
                name="lastName"
                label={messages.lastName}
                required
                validate={required(t('required'))}
                placeholder={messages.lastName}
                disabled={!!associateId}
              />
              <InputField
                type="email"
                name="email"
                label={messages.email}
                required
                validate={validation.email}
                placeholder={messages.email}
                disabled={!!associateId}
              />
              <InputField
                type="text"
                name="associateId"
                label={messages.associateId}
                required
                validate={validation.associateId}
                placeholder={messages.id}
                disabled={!!associateId}
              />

              {!associateId && (
                <>
                  <PasswordField
                    name="password"
                    label={messages.password}
                    validate={passwordValidators}
                    required
                    placeholder="********"
                  />
                  <PasswordField
                    name="confirmPassword"
                    label={messages.confirmNewPassword}
                    validate={passwordValidators}
                    required
                    placeholder="********"
                  />
                  <div className="sme-manage-admins-form__actions">
                    <Button
                      type="button"
                      onClick={handleCancel}
                      variant="link"
                      className="sme-manage-admins-form__actions__link"
                    >
                      {messages.cancel}
                    </Button>

                    <Button
                      disabled={submitDisabled}
                      type="submit"
                      variant="solid"
                      className="sme-manage-admins-form__actions__submit"
                    >
                      {messages.addAdmin}
                    </Button>
                  </div>
                </>
              )}
            </form>
          </>
        );
      }}
    />
  );
};

export default ManageAdminsForm;
