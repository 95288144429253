import httpClient from '../../httpClient';
import { Admin, AdminHistory, ManageAdminsFormBody } from 'models/User';
import { BaseParams, PaginatedReponse } from 'models/Pagination';

const adminsPath = (routePath: TemplateStringsArray | string) =>
  `admins${routePath}`;

const addAdmin = (adminInfo: ManageAdminsFormBody) => {
  return httpClient.post<Admin>(adminsPath``, adminInfo);
};

const getAdmins = (params: BaseParams) => {
  return httpClient.get<PaginatedReponse<Admin>>(adminsPath``, {
    params,
  });
};

const getHistory = (params: BaseParams) => {
  return httpClient.get<PaginatedReponse<AdminHistory>>(
    adminsPath`/template-history`,
    {
      params,
    },
  );
};

export default {
  addAdmin,
  getAdmins,
  getHistory,
};
