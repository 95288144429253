import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from './components/Routes/Routes';
import Auth from './subrouters/Auth/Auth.router';
import authRoutes from './subrouters/Auth/routes';
import Profile from './subrouters/Dashboard/pages/Profile';
import Dashboard from './subrouters/Dashboard/Dashboard.router';
import dashRoutes from './subrouters/Dashboard/routes';
import UserDashboard from './subrouters/UserDashboard/UserDashboard.router';
import userDashRoutes from './subrouters/UserDashboard/routes';
import StepFormProvider from 'providers/StepForm';

const emailLinks = ['template-select', 'file-upload', 'email-sent'];

const UserDashboardWrapped = (
  <StepFormProvider locations={emailLinks}>
    <UserDashboard />
  </StepFormProvider>
);

export default [
  {
    path: 'auth',
    element: Auth,
    onlyPublic: true,
    routes: authRoutes,
  },
  {
    path: 'dashboard',
    element: Dashboard,
    routes: dashRoutes,
    authorized: true,
  },
  {
    path: 'user-dashboard',
    element: UserDashboardWrapped,
    routes: userDashRoutes,
    authorized: true,
  },
  {
    path: 'profile',
    element: Profile,
    authorized: true,
  },
  {
    index: true,
    element: <Navigate to="dashboard" replace />,
  },
  {
    path: '*',
    element: () => `not found`,
  },
] as Array<ComplexRoute>;
