import React, { useContext, useMemo } from 'react';
import { RouteProps, Navigate, useLocation } from 'react-router-dom';
import credentialsService from 'services/credentialsService';
import { Role } from 'models/User';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';

export type EnhancedRouteProps = {
  authorized?: boolean;
  onlyPublic?: boolean;
  authorizedRoles?: Array<Role>;
} & Omit<RouteProps, 'element'> & {
    element: React.FC | React.ReactNode;
  };

const defaultRoutes = {
  Admin: {
    defaultRoute: 'dashboard',
  },
  Associate: {
    defaultRoute: 'user-dashboard',
  },
};

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const {
    authorized = false,
    onlyPublic = false,
    element,
    authorizedRoles,
  } = props;

  const isLoggedIn = !!credentialsService.token;

  const location = useLocation();

  const { currentUser, loading: loadingCurrentUser } =
    useContext(CurrentUserContext);

  const finalRoute = useMemo(() => {
    if ((authorized || authorizedRoles) && !isLoggedIn) {
      return (
        <Navigate
          to={{ pathname: '/auth/login' }}
          state={{ from: location.pathname }}
          replace
        />
      );
    }

    if (onlyPublic && isLoggedIn) {
      return <Navigate to="/" />;
    }

    if (authorizedRoles && currentUser) {
      const userAllowed = authorizedRoles?.some((authorizedRole) =>
        currentUser.roles.some((userRole) => authorizedRole === userRole),
      );

      if (!userAllowed) {
        return (
          <Navigate
            to={`/${defaultRoutes[currentUser.roles[0]]?.defaultRoute}`}
            replace
          />
        );
      }
    }

    if (typeof element === 'function') {
      const Component = element;

      return <Component />;
    }
    return element as React.ReactElement;
  }, [
    authorized,
    authorizedRoles,
    isLoggedIn,
    onlyPublic,
    location,
    element,
    currentUser,
  ]);

  if (loadingCurrentUser) {
    // Return null during the loading phase
    return null;
  }
  return finalRoute;
};

export default EnhancedRoute;
