import { AuthData } from '../models/AuthBody';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  get token(): string {
    return storageService.getItem<string>(STORAGE_KEYS.TOKEN);
  },
  get tmpToken(): string {
    return sessionStorage.getItem(STORAGE_KEYS.TOKEN);
  },
  set token(token: string | null) {
    storageService.setItem(STORAGE_KEYS.TOKEN, token);
  },

  get refreshToken(): string {
    return storageService.getItem<string>(STORAGE_KEYS.REFRESH_TOKEN);
  },

  set refreshToken(refreshToken: string | null) {
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  },

  saveTmpBody({ token, refreshToken }: AuthData) {
    sessionStorage.setItem(STORAGE_KEYS.TOKEN, token);
    sessionStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  },

  removeTmpBody() {
    sessionStorage.removeItem(STORAGE_KEYS.TOKEN);
    sessionStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  },

  consumeTmpBody() {
    const token = sessionStorage.getItem(STORAGE_KEYS.TOKEN);
    const refreshToken = sessionStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

    this.removeTmpBody();
    this.saveAuthBody({ token, refreshToken });
  },

  saveAuthBody({ token, refreshToken }: AuthData) {
    this.token = token;
    this.refreshToken = refreshToken;
  },

  removeAuthBody() {
    [STORAGE_KEYS.TOKEN, STORAGE_KEYS.REFRESH_TOKEN].forEach(
      storageService.removeItem,
    );
  },
};
