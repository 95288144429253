import { CurrentUserType } from 'models/User';
import { createContext } from 'react';

type UserContextProps = {
  currentUser: Partial<CurrentUserType> | null;
  loading: boolean;
  setCurrentUser: React.Dispatch<
    React.SetStateAction<Partial<CurrentUserType>>
  >;
  logout: () => void;
};

export default createContext<Partial<UserContextProps>>({});
