import api from 'api';
import React, { useCallback, useEffect, useState } from 'react';
import CurrentUserContext from './CurrentUser.context';
import { CurrentUserType } from 'models/User';
import credentialsService from 'services/credentialsService';
import utils from 'utils';
import refreshTokenService from 'services/refreshTokenService';
import bus from 'modules/bus';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { positionOptions } from 'types/types';

type CurrentUserProps = {
  children: React.ReactNode;
};

const CurrentUser: React.FC<CurrentUserProps> = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] =
    useState<Partial<CurrentUserType>>(null);

  const getUser = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.account.getCurrentUser();

      const mappedPositionData = {
        ...data,
        position: positionOptions[data?.position]?.label || '',
      };

      setCurrentUser(mappedPositionData);
    } catch (e) {
      utils.toastError(e);
      logout();
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    credentialsService.removeAuthBody();

    navigate('/auth');
  }, [navigate]);

  useEffect(() => {
    const customLogout = () => {
      refreshTokenService.reinit();
      logout();
      toast.error(t('Auth.sessionExpired'));
    };

    bus.addEventListener('LOGOUT_SIGNAL', customLogout);
    return () => {
      bus.removeEventListener('LOGOUT_SIGNAL', customLogout);
    };
  }, [t, logout]);

  useEffect(() => {
    if (!credentialsService.token && !credentialsService.tmpToken) {
      setLoading(false);
      return;
    }
    getUser();
  }, [getUser, credentialsService.token]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        loading,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUser;
