import React, { useCallback } from 'react';
import classNames from 'classnames';
import LeftArrow from 'icons/LeftArrow.icon';
import { NavigateFunction } from 'react-router-dom';
import './BackButton.styles.scss';

export type BackButtonProps = {
  className?: string;
  prevPath?: string;
  navigate: NavigateFunction;
  onBackClick?: (prevPath: string) => void;
};

const BackButton: React.FC<BackButtonProps> = (props) => {
  const { className, prevPath, navigate, onBackClick } = props;

  const classes = classNames('sme-back-button', className);

  const handleBackClick = useCallback(() => {
    if (!onBackClick) {
      navigate(-1);
    } else {
      onBackClick(prevPath);
    }
  }, [navigate, onBackClick, prevPath]);

  return (
    <button className={classes} type="button" onClick={handleBackClick}>
      <LeftArrow />
    </button>
  );
};

export default BackButton;
