import httpClient from '../../httpClient';
import { ManageUsersSubmitFormBody, User } from 'models/User';
import { BaseParams, PaginatedReponse } from 'models/Pagination';

const adminsPath = (routePath: TemplateStringsArray | string) =>
  `admins/associates${routePath}`;

const addAssociate = (userInfo: ManageUsersSubmitFormBody) => {
  return httpClient.post<User>(adminsPath``, userInfo);
};

const getAssociates = (params: BaseParams) => {
  return httpClient.get<PaginatedReponse<User>>(adminsPath``, {
    params,
  });
};

const getAssociate = (id: string) => {
  return httpClient.get<User>(adminsPath(`/${id}`));
};

const deleteAssociate = (id: string) => {
  return httpClient.delete(adminsPath(`/${id}`));
};

const updateAssociate = (
  userInfo: Partial<ManageUsersSubmitFormBody>,
  id: string,
) => {
  return httpClient.patch<User>(adminsPath(`/${id}`), userInfo);
};

export default {
  addAssociate,
  getAssociates,
  getAssociate,
  updateAssociate,
  deleteAssociate,
};
