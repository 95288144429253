import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="140" height="29" viewBox="0 0 140 29" fill="none" {...props}>
    <g clipPath="url(#clip0_5_860)">
      <path
        d="M123.463 14.3958H119.546V3.21897H123.463V0H119.503C118.676 0.00286167 117.882 0.338345 117.297 0.933255C116.711 1.52816 116.381 2.33422 116.378 3.17554V14.4392C116.382 15.2801 116.713 16.0853 117.298 16.6799C117.883 17.2745 118.676 17.6105 119.503 17.6147H123.463V14.3958Z"
        fill="#CC0000"
      />
      <path
        d="M126.631 3.21897H130.547V14.3958H126.631V17.6147H130.584C131.413 17.6119 132.206 17.2764 132.792 16.6815C133.377 16.0866 133.707 15.2805 133.71 14.4392V3.17554C133.706 2.33465 133.375 1.52943 132.79 0.934822C132.204 0.340218 131.412 0.0042794 130.584 0H126.631V3.21897Z"
        fill="#CC0000"
      />
      <path
        d="M8.9971 24.0418C4.28466 24.0418 0.795776 21.5231 0.21875 17.4681H5.02732C5.67381 19.0966 7.58119 19.6395 8.98636 19.6395C10.8885 19.6395 12.331 19.0532 12.331 17.9567C12.331 16.8602 11.6792 16.4585 8.44675 15.9483C5.14487 15.4597 0.720978 14.5423 0.720978 10.3408C0.720978 7.38242 3.27486 4.31003 8.63378 4.31003C12.9081 4.31003 16.0443 6.64961 16.648 10.1942H11.7272C11.4387 9.32031 10.3969 8.62548 8.63378 8.62548C6.44318 8.62548 5.54024 9.42885 5.54024 10.1237C5.54024 11.1062 6.90804 11.4374 9.21077 11.8011C13.8163 12.5718 17.1236 13.5924 17.1236 17.6853C17.1236 21.4145 13.7415 24.0418 8.9971 24.0418Z"
        fill="#CC0000"
      />
      <path
        d="M28.1669 23.5695C22.7332 24.1558 19.8588 20.8283 19.8588 15.6768V10.1943H17.6309V5.92224H19.8588V0H24.6086V5.92224H28.1669V10.1943H24.6086V15.742C24.6086 18.3693 25.7947 19.3572 28.1669 19.2106V23.5695Z"
        fill="#CC0000"
      />
      <path
        d="M60.235 24.0092C58.2075 24.0181 56.2579 23.2168 54.8066 21.7782V28.3953H49.998V4.78772H54.5662V6.75819C55.301 5.9867 56.1823 5.37481 57.1567 4.95959C58.1311 4.54437 59.1784 4.33447 60.235 4.3426C65.0917 4.3426 69.0508 8.58206 69.0508 14.1135C69.0508 19.6449 65.0917 23.9821 60.235 23.9821V24.0092ZM59.5564 8.81551C56.9278 8.81551 54.8066 11.1931 54.8066 14.1135C54.8066 17.0339 56.9438 19.4875 59.5564 19.4875C62.1691 19.4875 64.3009 17.1859 64.3009 14.1135C64.3009 11.0411 62.1638 8.81551 59.5564 8.81551Z"
        fill="#CC0000"
      />
      <path d="M75.3445 0H70.5947V23.6076H75.3445V0Z" fill="#CC0000" />
      <path
        d="M81.7077 16.0786C81.9455 17.1447 82.5354 18.0959 83.3788 18.7744C84.2229 19.4528 85.2695 19.8174 86.3453 19.8078C87.1316 19.8447 87.9132 19.6619 88.605 19.279C89.2962 18.8962 89.8712 18.3281 90.2667 17.6365H95.321C94.2526 21.4742 90.9721 24.0635 86.3664 24.0635C83.828 24.0306 81.4052 22.9792 79.6267 21.1386C77.8481 19.298 76.858 16.8176 76.8722 14.2384C76.8722 8.81005 81.147 4.33176 86.3664 4.33176C91.8325 4.33176 96.3628 8.67438 95.6791 16.1002L81.7077 16.0786ZM90.9884 12.1322C90.7329 11.0865 90.1328 10.161 89.288 9.50937C88.444 8.8577 87.4055 8.51902 86.3453 8.54954C85.3021 8.55244 84.2888 8.9052 83.4624 9.55301C82.6367 10.2007 82.0441 11.1074 81.777 12.1322H90.9884Z"
        fill="#CC0000"
      />
      <path
        d="M105.302 11.8011C103 11.4374 101.632 11.1062 101.632 10.1237C101.632 9.42885 102.53 8.62548 104.725 8.62548C106.488 8.62548 107.53 9.32031 107.819 10.1942H112.745C112.136 6.64961 108.967 4.31003 104.731 4.31003C99.3877 4.31003 96.8181 7.38242 96.8181 10.3408C96.8181 14.5423 101.242 15.4597 104.549 15.9699C107.856 16.4802 108.433 16.9199 108.433 17.9784C108.433 19.0369 106.996 19.6612 105.089 19.6612C103.732 19.6612 101.883 19.1184 101.204 17.6147H96.3369C96.9785 21.5828 100.435 24.0418 105.089 24.0418C109.839 24.0418 113.22 21.4145 113.22 17.6853C113.22 13.5924 109.908 12.5718 105.308 11.8011"
        fill="#CC0000"
      />
      <path
        d="M37.6189 4.38605C39.6469 4.37785 41.5974 5.17674 43.0526 6.61164V4.78774H47.8612V23.6076H43.2664V21.6317C42.5333 22.3991 41.6552 23.0081 40.6847 23.4222C39.7143 23.8363 38.6715 24.0471 37.6189 24.0418C32.7623 24.0418 28.8086 19.8078 28.8086 14.2709C28.8086 8.73412 32.7623 4.38605 37.6189 4.38605ZM38.3028 19.5418C40.9262 19.5418 43.0526 17.1697 43.0526 14.2438C43.0526 11.3179 40.9155 8.87525 38.3028 8.87525C35.6902 8.87525 33.553 11.1768 33.553 14.2438C33.553 17.3108 35.6902 19.5418 38.3028 19.5418Z"
        fill="#CC0000"
      />
      <path
        d="M135.789 1.40048H137.29V1.89988H136.798V3.21895H136.264V1.89988H135.772L135.789 1.40048ZM139.529 1.40048V3.21895H138.994V2.24186L138.502 2.86611L138.065 2.24186V3.21895H137.53V1.40048H137.99L138.524 2.1333L139.058 1.40048H139.529Z"
        fill="#CC0000"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_860">
        <rect width="140" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
