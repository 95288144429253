import React, { useContext } from 'react';
import classNames from 'classnames';

import Header from 'components/Header';
import { Button } from 'ncoded-component-library';
import { Outlet, useNavigate } from 'react-router-dom';
import SideBar from 'components/SideBar';
import { useTranslation } from 'react-i18next';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';

import './Dashboard.styles.scss';
import Avatar from 'components/Avatar';
import LanguagePicker from 'components/LanguagePicker';

type DashboardProps = {
  className?: string;
};

const Dashboard: React.FC<DashboardProps> = (props) => {
  const { className } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { logout } = useContext(CurrentUserContext);

  const classes = classNames('sme-dashboard', className);

  return (
    <>
      <Header
        rightComponent={
          <>
            <LanguagePicker key="lp" />
            <Avatar onClick={() => navigate('/profile')} />
            <Button variant="outline" onClick={logout}>
              {t('logout')}
            </Button>
          </>
        }
      />
      <SideBar />
      <main className={classes}>
        <Outlet />
      </main>
    </>
  );
};

export default Dashboard;
