import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Field, Form, FormProps } from 'react-final-form';
import { SubmissionErrors } from 'final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { Button, Loader } from 'ncoded-component-library';
import useAssociate from './hooks/useAssociate';
import formValidators, { MAX_ASSOCIATE_ID_CHARS } from 'utils/formValidators';
import { ManageUsersFormBody, GroupNames, User } from 'models/User';
import SelectField from 'components/SelectField';
import { positionOptions } from 'types/types';
import MultipleSelectField from 'components/MultipleSelectField';
import { DebouncedFunc } from 'lodash';

import './ManageUsersForm.styles.scss';

const { required } = formValidators;

type ManageUsersFormProps = {
  className?: string;
  associateId?: string;
  inProgress: boolean;
  groupNames?: GroupNames[];
  handleCancel?: () => void;
  onSubmit: (
    values: ManageUsersFormBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  changeGroupsParams?: DebouncedFunc<(searchString: string) => void>;
} & FormProps<ManageUsersFormBody>;

const ManageUsersForm: React.FC<ManageUsersFormProps> = (props) => {
  const {
    className,
    associateId,
    inProgress,
    handleCancel,
    onSubmit,
    groupNames,
    changeGroupsParams,
  } = props;

  const { associate, loading: loadingUser } = useAssociate(associateId);

  const [changePass, setChangePass] = useState(false);

  const classes = classNames('sme-manage-users-form', className);

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      firstName: t('General.firstName'),
      lastName: t('General.lastName'),
      associateId: t('General.associateId'),
      position: t('General.position'),
      email: t('General.email'),
      groups: t('Groups.groups'),
      selectGroup: t('Groups.selectGroup'),
      password: t('General.password'),
      confirmNewPassword: t('General.confirmNewPassword'),
      cancel: t('General.cancel'),
      addAssociate: t('addAssociate'),
      updateAssosiate: t('updateAssociate'),
      passwordMismatch: t('General.passwordMismatch'),
      id: t('id'),
      changePass: t('General.changePassword'),
    }),
    [t],
  );

  const passwordMatchValidator = useCallback(
    (values: ManageUsersFormBody) => {
      const errors: Partial<ManageUsersFormBody> = {};

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = messages.passwordMismatch;
      }

      return errors;
    },
    [messages.passwordMismatch],
  );

  const validation = useMemo(
    () => ({
      associateId: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.maxChars(
          t('maxChars', { chars: MAX_ASSOCIATE_ID_CHARS }),
          MAX_ASSOCIATE_ID_CHARS,
        ),
        formValidators.noSpaces(t('noSpaces')),
      ),

      email: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.getEmailValidators(t),
      ),
    }),
    [t],
  );

  const initialAssociateValues = useMemo(
    () => ({
      firstName: associate?.firstName,
      lastName: associate?.lastName,
      associateId: associate?.associateId,
      position: associate?.position,
      email: associate?.email,
      groups: associate?.groups?.map((group) => group.id),
      selectedGroups: associate?.groups?.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    }),
    [associate],
  );

  console.log({ initialAssociateValues });

  const positionOptionsMapped = useMemo(
    () =>
      Object.values(positionOptions).map(({ value, label }) => ({
        value,
        label: t(`Positions.${label}`),
      })),
    [t],
  );

  const groupOptionsMapped = useMemo(
    () =>
      groupNames.map(({ id, name }) => {
        return {
          label: name,
          value: id,
        };
      }),
    [groupNames],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={associate ? initialAssociateValues : {}}
      validate={passwordMatchValidator}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, dirty, values } = formRenderProps;

        const submitDisabled = invalid || inProgress || !dirty;

        const { position } = values;

        return (
          <>
            {(inProgress || loadingUser) && <Loader color="#cc0000" />}
            <form className={classes} onSubmit={handleSubmit}>
              <InputField
                type="text"
                name="firstName"
                label={messages.firstName}
                required
                validate={required(t('required'))}
                placeholder={messages.firstName}
              />
              <InputField
                type="text"
                name="lastName"
                label={messages.lastName}
                required
                validate={required(t('required'))}
                placeholder={messages.lastName}
              />
              <InputField
                type="email"
                name="email"
                label={messages.email}
                required
                validate={validation.email}
                placeholder={messages.email}
              />
              <InputField
                type="text"
                name="associateId"
                label={messages.associateId}
                required
                validate={validation.associateId}
                placeholder={messages.id}
              />
              <SelectField
                name="position"
                options={positionOptionsMapped}
                label={messages.position}
                validate={required(t('required'))}
                innerLabel={!position && t('selectAnOption')}
              />
              <Field
                component={MultipleSelectField}
                name="groups"
                label={messages.groups}
                placeholder={messages.selectGroup}
                options={groupOptionsMapped}
                selectedOptionsName="selectedGroups"
                onSearchChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  changeGroupsParams(event?.target?.value || '')
                }
              />

              {associateId && (
                <Button
                  type="button"
                  onClick={() => setChangePass((old) => !old)}
                  variant="link"
                  className="sme-manage-users-form__change-pass-link"
                >
                  <span>
                    {changePass ? messages.cancel : messages.changePass}
                  </span>
                </Button>
              )}

              {(!associateId || changePass) && (
                <>
                  <PasswordField
                    name="password"
                    label={messages.password}
                    validate={formValidators.getPasswordValidators(t)}
                    required
                    placeholder="********"
                  />
                  <PasswordField
                    name="confirmPassword"
                    label={messages.confirmNewPassword}
                    validate={formValidators.getPasswordValidators(t)}
                    required
                    placeholder="********"
                  />
                </>
              )}
              <div className="sme-manage-users-form__actions">
                <Button
                  type="button"
                  onClick={handleCancel}
                  variant="link"
                  className="sme-manage-users-form__actions__link"
                >
                  {messages.cancel}
                </Button>

                <Button
                  disabled={submitDisabled}
                  type="submit"
                  variant="solid"
                  className="sme-manage-users-form__actions__submit"
                >
                  {associateId
                    ? messages.updateAssosiate
                    : messages.addAssociate}
                </Button>
              </div>
            </form>
          </>
        );
      }}
    />
  );
};

export default ManageUsersForm;
