import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import SearchIcon from 'icons/Search.icon';
import './styles.scss';

interface DebounceInputProps {
  initialValue?: string;
  onChange?: (value?: string) => void;
  placeholder?: string;
  debounce?: number;
}

const DebounceInput: React.FC<DebounceInputProps> = ({
  initialValue,
  onChange = () => {},
  placeholder = '',
  debounce = 1000,
}) => {
  const [value, setValue] = useState<string | undefined>(initialValue);
  const debouncedValue = useDebounce(value, debounce);

  useEffect(() => {
    if (debouncedValue !== undefined) {
      onChange(debouncedValue);
    } else {
      onChange();
    }
  }, [debouncedValue, onChange]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  return (
    <div className="ickyc-debounce-input">
      <SearchIcon />
      <input
        data-lpignore="true"
        placeholder={placeholder}
        value={value || ''}
        onChange={handleInputChange}
      />
    </div>
  );
};

DebounceInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
};

DebounceInput.defaultProps = {
  initialValue: undefined,
  onChange: () => {},
  placeholder: '',
  debounce: 1000,
};

export default DebounceInput;
