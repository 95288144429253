import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import EmailSent from './pages/EmailSent';
import FileUpload from './pages/FileUpload';
import TemplateSelect from './pages/TemplateSelect';

export default [
  {
    path: 'template-select',
    element: TemplateSelect,
  },
  {
    path: 'file-upload',
    element: FileUpload,
  },
  {
    path: 'email-sent',
    element: EmailSent,
  },
  {
    index: true,
    element: <Navigate to="template-select" replace />,
  },
] as Array<EnhancedRouteProps>;
