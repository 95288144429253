import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import routes from 'router/routes';
import Routes from 'router/components/Routes/Routes';
import ErrorBoundary, { ErrorFallback } from 'components/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import './i18n/config';
import CurrentUserProvider from 'providers/CurrentUser';

function App() {
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <BrowserRouter>
        <CurrentUserProvider>
          <Routes routes={routes} />
        </CurrentUserProvider>
      </BrowserRouter>
      <ToastContainer />
    </ErrorBoundary>
  );
}

export default App;
