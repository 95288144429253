const LOWERCASE_LETTERS = /(.*[a-z])/g;
const UPPERCASE_LETTERS = /(.*[A-Z])/g;
const NUMBER = /(.*[0-9])/g;
const SPECIAL_CHAR = /(.*[*.!@#$%^&(){}[\]:";'<>,.?/~`_+\-=|\\].*)/g;
const MIN_LENGTH = /(.{7,})/g;

// Source: http://www.regular-expressions.info/email.html
// See the link above for an explanation of the tradeoffs.
const EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,}$/;

export default {
  LOWERCASE_LETTERS,
  UPPERCASE_LETTERS,
  NUMBER,
  SPECIAL_CHAR,
  MIN_LENGTH,
  EMAIL,
  PASSWORD,
};
