import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useFormState } from 'react-final-form';
import TemplateCards from './components/TemplateCards';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import StepFormContext from 'providers/StepForm/StepForm.context';
import useStyleWrapTemplates from './hooks/useStyleWrapTemplates';
import Modal, { ModalRef } from 'components/Modal';
import { mappedTemplateKeys } from '../../utils';
import { toast } from 'react-toastify';
import utils from 'utils';
import api from 'api';

import './TemplateSelect.styles.scss';
import './TemplateSelect.styles.responsive.scss';

type TemplateSelectProps = {
  className?: string;
};

const TemplateSelect: React.FC<TemplateSelectProps> = (props) => {
  const { className } = props;

  const classes = classNames('template-select', className);

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>();

  const { goToNextStep } = useContext(StepFormContext);

  const {
    values: { templates: formTemplates, selectedTemplate },
  } = useFormState();

  const templates = useMemo(
    () => useStyleWrapTemplates(formTemplates),
    [formTemplates],
  );

  const modalRef = useRef<ModalRef>();

  const openPreview = useCallback(() => {
    setPreviewIndex(
      templates.findIndex((template) => template.name === selectedTemplate),
    );

    modalRef.current.open();
  }, [modalRef, selectedTemplate]);

  const downloadTemplate = useCallback(async () => {
    const templateName =
      mappedTemplateKeys[selectedTemplate as keyof typeof mappedTemplateKeys];

    try {
      const response = await api.templates.getTemplateFile(templateName);

      if (!response.ok) {
        throw new Error('Failed to retrieve template file.');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.setAttribute('download', `${templateName}.xlsx`);

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(utils.handleResponseError(error));
    }
  }, [selectedTemplate]);

  const navigatePreview = useCallback(
    (forward: boolean) => {
      setPreviewIndex((prev) => (forward ? prev + 1 : prev - 1));
    },
    [previewIndex],
  );

  const messages = useMemo(
    () => ({
      layoutHeader: t('UserDashboard.TemplateSelect.layoutHeader'),
      layoutText: t('UserDashboard.TemplateSelect.layoutText'),
      preview: t('UserDashboard.TemplateSelect.preview'),
      download: t('UserDashboard.TemplateSelect.download'),
      nextStep: t('General.nextStep'),
      next: t('General.next'),
      previous: t('General.previous'),
      of: t('History.of'),
    }),
    [t],
  );

  return (
    <div className={classes}>
      <div className="template-select__header">
        <h2>{messages.layoutHeader}</h2>
        <div>{messages.layoutText}</div>
      </div>
      {templates.length < 1 && <Loader color="#cc0000" inline />}
      <TemplateCards />
      <div className="template-select__form-navigation">
        <Button
          variant="outline"
          onClick={downloadTemplate}
          disabled={!selectedTemplate}
        >
          {messages.download}
        </Button>
        <Button
          variant="outline"
          onClick={openPreview}
          disabled={!selectedTemplate}
        >
          {messages.preview}
        </Button>
        <Button onClick={goToNextStep} disabled={!selectedTemplate}>
          {messages.nextStep}
        </Button>
      </div>

      <Modal
        renderAsPortal
        focusableElements="div"
        className="template-cards-modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-associate"
        addSearchParams={false}
        keepOpenOnRefresh={false}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        onX={() => modalRef.current.close()}
        title={
          <div className="template-cards-modal__header">
            {templates[previewIndex]?.name}
          </div>
        }
        footer={
          <div className="template-cards-modal__navigation">
            <Button
              variant="outline"
              onClick={() => {
                navigatePreview(false);
              }}
              disabled={previewIndex === 0}
            >
              {messages.previous}
            </Button>
            <div>{`${previewIndex + 1} ${messages.of} ${
              templates.length
            }`}</div>
            <Button
              variant="outline"
              onClick={() => {
                navigatePreview(true);
              }}
              disabled={previewIndex === templates?.length - 1}
            >
              {messages.next}
            </Button>
          </div>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: templates[previewIndex]?.html,
          }}
        />
      </Modal>
    </div>
  );
};

export default TemplateSelect;
