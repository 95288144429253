import httpClient from 'api/httpClient';
import env from 'env';
import { TemplatePagination } from 'models/Templates';
import bus, { AuthEventTypes } from 'modules/bus';
import credentialsService from 'services/credentialsService';

const templatesPath = (routePath: TemplateStringsArray | string) =>
  `templates${routePath}`;

const getTemplates = () => {
  return httpClient.get<TemplatePagination>(templatesPath``);
};

const uploadTemplate = (formData: FormData) => {
  return httpClient.post(templatesPath``, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getTemplateFile = (key: string) => {
  const token = credentialsService.token || credentialsService.tmpToken;

  if (!token) {
    bus.broadcastEvent(AuthEventTypes.LOGOUT_SIGNAL);
  }

  return fetch(`${env.SERVER_ENDPOINT}/api/v1/templates/file/${key}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'ngrok-skip-browser-warning': 'true',
    },
  });
};

export default {
  getTemplates,
  uploadTemplate,
  getTemplateFile,
};
