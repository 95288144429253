import { useEffect, useMemo, useState } from 'react';
import { useField, useFormState } from 'react-final-form';
import * as XLSX from 'xlsx';

export const mappedSubmitErrors = {
  INVALID_FILE: 'Invalid file was submited.',
  INVALID_CELLS_COUNT: 'Invalid number of columns.',
  INVALID_EMAIL: 'Invalid email was submited in one of the columns.',
};

export default function () {
  const [fileData, setFileData] = useState(null);

  const {
    values: { attachment, scheduleDate, scheduleTime, isScheduled },
    submitError,
    submitting,
    dirtySinceLastSubmit: dirtyAttachment,
  } = useFormState();

  const {
    meta: { dirtySinceLastSubmit: dirtyTemplate },
  } = useField('selectedTemplate');

  const error = !!submitError && !dirtyAttachment && !dirtyTemplate;

  const dropzoneError =
    error &&
    !!mappedSubmitErrors[submitError as keyof typeof mappedSubmitErrors];

  const scheduleError =
    error &&
    !mappedSubmitErrors[submitError as keyof typeof mappedSubmitErrors];

  const isInvalidSchedule = isScheduled && (!scheduleDate || !scheduleTime);

  const disableSubmit =
    !attachment ||
    submitting ||
    isInvalidSchedule ||
    scheduleError ||
    dropzoneError;

  const currentDate = new Date().toISOString().split('T')[0];

  const currentTime = new Date(new Date().getTime() + 60000)
    .toLocaleTimeString()
    .substring(0, 5);

  useEffect(() => {
    const readUploadedFile = () => {
      if (!attachment) return;

      attachment.forEach(async (file: any) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onload = async (e) => {
          if (e?.target?.result !== undefined) {
            const bstr = e.target.result;
            const workbook = XLSX.read(bstr, {
              type: 'array',
            });

            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];

            const data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });

            setFileData(data);
          }
        };
      });
    };

    readUploadedFile();
  }, [attachment]);

  const filteredFileData: string[][] = useMemo(() => {
    if (!fileData) return null;

    const filterHeaders: string[] = ['contactValue', 'logo', 'locale'];

    const filterIndices: number[] = fileData[0]
      .map((tableHeader: any, index: number) =>
        filterHeaders.some((filter) => tableHeader.includes(filter))
          ? index
          : -1,
      )
      .filter((index: number) => index !== -1);

    return fileData.map((row: any) => filterIndices.map((index) => row[index]));
  }, [fileData]);

  return {
    errors: { dropzoneError, scheduleError, disableSubmit },
    state: { fileData, filteredFileData, currentDate, currentTime, attachment },
  };
}
