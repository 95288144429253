import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M7.75 12C7.75 11.3096 8.30964 10.75 9 10.75H19C19.6904 10.75 20.25 11.3096 20.25 12V21C20.25 22.2426 19.2426 23.25 18 23.25H10C8.75736 23.25 7.75 22.2426 7.75 21V12Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M8 8H11M20 8H17M11 8V7C11 5.89543 11.8954 5 13 5H15C16.1046 5 17 5.89543 17 7V8M11 8H17"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14V20"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 14V20"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
