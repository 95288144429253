import httpClient from 'api/httpClient';
import { BaseParams, PaginatedReponse } from 'models/Pagination';
import { AssociateHistory } from 'models/User';

const associatesPath = (routePath: TemplateStringsArray | string) =>
  `associates${routePath}`;

const getHistory = (params: BaseParams) => {
  return httpClient.get<PaginatedReponse<AssociateHistory>>(
    associatesPath`/template-history`,
    {
      params,
    },
  );
};

export default {
  getHistory,
};
