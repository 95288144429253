import React from 'react';
import classNames from 'classnames';
import StaplesLogo from 'icons/StaplesLogo.icon';
import { Link } from 'react-router-dom';

import './Header.styles.scss';
import './Header.styles.responsive.scss';

export type HeaderProps = {
  className?: string;
  rightComponent?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { className, rightComponent } = props;

  const classes = classNames('sme-header', className);

  return (
    <>
      <header className={classes}>
        <Link to="/">
          <StaplesLogo />
        </Link>
        <div className="sme-header__right-component">{rightComponent}</div>
      </header>
    </>
  );
};

export default Header;
