export const mappedTemplateKeys = {
  'SBA CA BO AutoSub Template EN': 'SbaCaBoAutoSubTemplate',
  'SBA CA BO AutoSub Template FR': 'SbaCaBoAutoSubTemplate',
  'SBA CA Return Confirmation Adhoc EN': 'SbaCaReturnConfirmTemplate',
  'SBA CA Return Confirmation Adhoc FR': 'SbaCaReturnConfirmTemplate',
  'SBA CA MR Request from FC EN': 'SbaCaMrRequestFromFcTemplate',
  'SBA CA MR Request from FC FR': 'SbaCaMrRequestFromFcTemplate',
  'SBA CA Aged Order Cancel No Stock EN': 'SbaCaAgedOrderCancelNoStockTemplate',
  'SBA CA Aged Order Cancel No Stock FR': 'SbaCaAgedOrderCancelNoStockTemplate',
  'SBA CA Aged Order Cancel  Item Sub EN':
    'SbaCaAgedOrderCancelItemSubTemplate',
  'SBA CA Aged Order Cancel  Item Sub FR':
    'SbaCaAgedOrderCancelItemSubTemplate',
  'SBA CA Declined Credit Card Transaction EN': 'DccAdHocTemplate',
  'SBA CA Declined Credit Card Transaction FR': 'DccAdHocTemplate',
  'SBA CA Delayed Delivery Next Day EN': 'SbaCaDelayedDeliveryTemplate',
  'SBA CA Delayed Delivery Next Day FR': 'SbaCaDelayedDeliveryTemplate',
  'SBA CA Delayed Delivery Same Day EN': 'SbaCaDelayedDeliveryTemplate',
  'SBA CA Delayed Delivery Same Day FR': 'SbaCaDelayedDeliveryTemplate',
};
