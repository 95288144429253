import { AxiosError } from 'axios';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query: string) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || 'DocumentTouch' in window) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'ncoded', ')'].join(
    '',
  );
  return mq(query);
}

export const parseStringValue = (value: any) =>
  /^\d+$/.test(value)
    ? +value
    : /^(true|false)$/.test(value)
    ? JSON.parse(value)
    : value;

export function convertFormDataToJSONObject(formData: FormData) {
  const obj: any = {};
  formData.forEach((val, key) => {
    const isArray = key.includes('[]') || key.includes('files');

    if (isArray) {
      const newKey = key.split('[]')[0];
      if (!obj[newKey]) obj[newKey] = [];
      obj[newKey].push(parseStringValue(val));
    } else {
      obj[key] = parseStringValue(val);
    }
  });
  return obj;
}

export const convertObjToFormData = (
  obj: Record<string, any>,
  formData = new FormData(),
  path = '',
) => {
  if (obj === undefined) return;

  for (const prop in obj) {
    const newPath = path ? `${path}[${prop}]` : prop;
    if (typeof obj[prop] !== 'object') {
      if (obj[prop] instanceof File) {
        const file: File = obj[prop];
        formData.append(newPath, file, file.name);
      } else {
        formData.append(newPath, obj[prop]);
      }
    } else if (obj[prop] === null) {
      formData.append(newPath, obj[prop]);
    } else {
      convertObjToFormData(obj[prop], formData, newPath);
    }
  }

  return formData;
};

export function debounce(
  func: (...args: any[]) => void,
  wait: number,
  isImmediate = false,
) {
  let timeout: NodeJS.Timeout | null;
  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      if (!isImmediate) {
        func(...args);
      }
    };
    const callNow = isImmediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
}
const isAxiosError = (e: AxiosError | unknown): e is AxiosError =>
  'message' in (e as AxiosError) || 'response' in (e as AxiosError);

function toastError(e: AxiosError | unknown) {
  if (isAxiosError(e)) {
    toast.error(e.message || (e?.response.data as any)?.message);
    return;
  }

  toast.error('An error occured');
}

// Transforms "BACKEND_ERROR" to "Backend error"
const normalizeErrors = (errData: any) => {
  return (
    errData.errors
      .map((error: any) =>
        error
          .split('_')
          .map((word: string, index: number) => {
            if (index === 0) {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            } else {
              return word.toLowerCase();
            }
          })
          .join(' '),
      )
      .join('') + '.'
  );
};

const handleResponseError = (err: any) => {
  const { status, data: errData } = err.response || {};

  if (status >= 400 && status < 500) {
    return Array.isArray(errData.errors)
      ? normalizeErrors(errData)
      : errData.message || i18next.t('Errors.serverError');
  }
  if (status === 500) {
    return Array.isArray(errData.message)
      ? errData.message.join('')
      : errData.message || i18next.t('Errors.internalServerError');
  }
  return i18next.t('General.error');
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop(): any {}

function transformDateFormat(inputDate: string): string {
  // Convert GMT from EST
  const gmtDateTime = DateTime.fromISO(inputDate, { zone: 'utc' });
  const estDateTime = gmtDateTime.setZone('America/New_York');
  const estTimestampStr = estDateTime.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS");

  const dateObject = new Date(estTimestampStr);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');
  const hour = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');

  const transformedDate = `${year}-${month}-${day} ${hour}:${minutes}`;

  return transformedDate;
}

export default {
  noop,
  toastError,
  isTouchDevice,
  parseStringValue,
  convertObjToFormData,
  convertFormDataToJSONObject,
  debounce,
  handleResponseError,
  transformDateFormat,
};
