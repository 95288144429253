import api from 'api';
import usePaginatedItems from 'hooks/usePaginatedItems';
import { ManageAdminsFormBody } from 'models/User';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { positionOptions } from 'types/types';
import utils from 'utils';

type HookProps = {
  modalRef: React.MutableRefObject<OverlayRef>;
  setAssociateId: React.Dispatch<React.SetStateAction<string>>;
};

export default function useManageAdminsHandlers(props: HookProps) {
  const { modalRef, setAssociateId } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    items: admins,
    loading: fetching,
    changeParams,
    pagination,
  } = usePaginatedItems(api.admins.admins.getAdmins);

  const addAssociate = useCallback(
    async (values: ManageAdminsFormBody) => {
      try {
        setLoading(true);
        await api.admins.admins.addAdmin(values);
        toast.success(t('successAddingAssociate'));
        modalRef.current.close();
        setAssociateId('');
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      } finally {
        setLoading(false);
      }
    },
    [t],
  );

  const normalizedAdmins = admins?.map(({ position, ...rest }) => {
    return {
      ...rest,
      position: t(`Positions.${positionOptions[position].label}`) || '',
    };
  });

  return {
    addAssociate,
    normalizedAdmins,
    fetching,
    loading,
    changeParams,
    pagination,
  };
}
