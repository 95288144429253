import React, { useMemo } from 'react';
import { useAuth } from '../../Auth.router';
import { Form } from 'react-final-form';
import InputField from 'components/InputField';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './ForgotPassword.styles.scss';
import './ForgotPassword.styles.responsive.scss';

type ForgotPasswordProps = {
  className?: string;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const { className } = props;

  const classes = classNames('reset-password', className);

  const { forgotPassword, isAuthInProgress } = useAuth();

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      email: t('General.email'),
      emailInvalid: t('General.emailInvalid'),
      paragraph: t('Auth.ForgotPassword.paragraph'),
      requestButton: t('Auth.ForgotPassword.requestButton'),
      backToLogin: t('Auth.backToLogin'),
    }),
    [t],
  );

  return (
    <Form
      onSubmit={forgotPassword}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <p>{messages.paragraph}</p>
            <InputField
              type="email"
              name="email"
              label={messages.email}
              placeholder={messages.email}
              validate={formValidators.required(messages.emailInvalid)}
            />

            <Link className="forgot-password__auth-link" to="/auth">
              {messages.backToLogin}
            </Link>
            <Button
              className="forgot-password__submit"
              type="submit"
              variant="solid"
              disabled={invalid || isAuthInProgress}
            >
              {messages.requestButton}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default ForgotPassword;
