import { useCallback, useEffect, useState } from 'react';
import { BaseParams, PaginatedReponse, Pagination } from 'models/Pagination';
import { AxiosResponse } from 'axios';
import useQParamsActions from './useQueryParams';

const usePaginatedItems = <T>(
  request: (
    params: BaseParams,
  ) => Promise<AxiosResponse<PaginatedReponse<T>, any>>,
): {
  items?: T[];
  changeParams: (params: BaseParams) => void;
  refresh: () => void;
  pagination: Pagination;
  loading?: boolean;
} => {
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>();
  const [items, setItems] = useState<T[]>([]);

  const { params, setMultipleQueryParams } = useQParamsActions();

  const changeParams = useCallback(
    (newParams: BaseParams) => setMultipleQueryParams(newParams),
    [],
  );

  const fetchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await request(params);
      const { items, ...pagination } = data;

      setItems(items);
      setPagination(pagination);
    } catch (err) {
      console.error();
    } finally {
      setLoading(false);
    }
  }, [params, request]);

  const refresh = useCallback(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return {
    items,
    refresh,
    changeParams,
    pagination,
    loading,
  };
};

export default usePaginatedItems;
