import React, { useMemo } from 'react';
import { useAuth } from '../../Auth.router';
import { Form } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import classNames from 'classnames';
import PasswordField from 'components/PasswordField';
import { Link } from 'react-router-dom';

import './ResetPassword.styles.scss';
import './ResetPassword.styles.responsive.scss';

type ResetPasswordProps = {
  className?: string;
};

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const { className } = props;

  const classes = classNames('reset-password', className);

  const { resetPassword, isAuthInProgress } = useAuth();

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      password: t('General.password'),
      newPassword: t('General.newPassword'),
      passwordRequired: t('General.passwordRequired'),
      requestButton: t('Auth.ResetPassword.requestButton'),
      backToLogin: t('Auth.backToLogin'),
    }),
    [t],
  );

  return (
    <Form
      onSubmit={resetPassword}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <PasswordField
              name="password"
              label={messages.password}
              placeholder={messages.password}
              validate={formValidators.getPasswordValidators(t)}
            />
            <PasswordField
              name="confirmPassword"
              label={messages.newPassword}
              placeholder={messages.newPassword}
              validate={formValidators.getPasswordValidators(t)}
            />

            <Link className="forgot-password__auth-link" to="/auth">
              {messages.backToLogin}
            </Link>

            <Button
              className="reset-password__submit"
              type="submit"
              variant="solid"
              disabled={invalid || isAuthInProgress}
            >
              {messages.requestButton}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default ResetPassword;
