export type ExtractPropTypes<Type> = Type extends React.FC<infer P> ? P : never;

export type DateFormats =
  | 'dd/mm/yyyy'
  | 'mm/dd/yyyy'
  | 'yyyy/mm/dd'
  | 'dd.mm.yyyy'
  | 'mm.dd.yyyy'
  | 'yyyy.mm.dd'
  | 'dd-mm-yyyy'
  | 'mm-dd-yyyy'
  | 'yyyy-mm-dd';

export type Positions =
  | 'DistrictManager'
  | 'StoreOps'
  | 'Associate'
  | 'Cio'
  | 'CopyCentreLead'
  | 'CopyCentreRepresentative'
  | 'CustomerServiceRep'
  | 'GeneralManagerVolumeC'
  | 'Receiver'
  | 'SalesAreaRepresentative'
  | 'Admin';

export const positionOptions: {
  label: Positions;
  value: number;
}[] = [
  {
    label: 'DistrictManager',
    value: 0,
  },
  {
    label: 'StoreOps',
    value: 1,
  },
  {
    label: 'Associate',
    value: 2,
  },
  {
    label: 'Cio',
    value: 3,
  },
  {
    label: 'CopyCentreLead',
    value: 4,
  },
  {
    label: 'CopyCentreRepresentative',
    value: 5,
  },
  {
    label: 'CustomerServiceRep',
    value: 6,
  },
  {
    label: 'GeneralManagerVolumeC',
    value: 7,
  },
  {
    label: 'Receiver',
    value: 8,
  },
  {
    label: 'SalesAreaRepresentative',
    value: 9,
  },
  {
    label: 'Admin',
    value: 10,
  },
];

('SbaCaAgedOrderCancelItemSubTemplate');
('SbaCaAgedOrderCancelNoStockTemplate');
('DccAdHocTemplate');
('SbaCaMrRequestFromFcTemplate');
('SbaCaReturnConfirmTemplate');

export const templatesOptions = [
  {
    label: '1',
    value: 'SbaCaAgedOrderCancelItemSubTemplate',
  },
  {
    label: '2',
    value: 'SbaCaAgedOrderCancelNoStockTemplate',
  },
  {
    label: '3',
    value: 'DccAdHocTemplate',
  },
  {
    label: '4',
    value: 'SbaCaMrRequestFromFcTemplate',
  },
  {
    label: '5',
    value: 'SbaCaReturnConfirmTemplate',
  },
];
