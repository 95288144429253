import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 291 291"
    width="20"
    height="20"
    strokeWidth="30px"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      transform="scale(0.284417 0.284417)"
      d="M501.515 128.192C509.438 127.757 517.95 127.527 525.835 128.403C572.152 133.552 611.315 170.28 618.028 216.785C627.35 281.372 586.929 334.489 521.116 343.454L521.015 343.48C493.161 346.091 466.64 337.153 444.786 320.069C369.44 261.17 404.637 138.146 501.515 128.192ZM509.786 192.191C450.66 197.745 457.009 280.784 514.939 279.102C527.794 277.834 537.964 272.888 546.228 262.768L547.973 260.323C569.161 229.383 545.737 191.891 509.786 192.191Z"
    />
    <path
      transform="scale(0.284417 0.284417)"
      d="M235.516 127.974C303.493 127.793 353.237 182.432 344.356 250.828C337.12 306.548 292.04 343.533 236.93 343.986C183.616 343.008 136.809 305.374 129.02 251.844C119.699 187.787 170.018 127.045 235.516 127.974ZM231.291 192.647C174.002 202.21 184.462 284.649 243.869 279.597C302.987 267.588 287.357 186.762 231.291 192.647Z"
    />
    <path
      transform="scale(0.284417 0.284417)"
      d="M775.327 128.198C804.215 126.452 830.347 132.056 854 149.984C932.164 209.231 894.603 330.969 800.624 343.405C791.879 344.32 781.958 344.643 773.25 343.612C726.316 338.06 685.097 299.145 679.491 251.719C672.165 189.735 712.595 135.412 775.327 128.198ZM782.256 192.654C764.179 195.411 749.118 206.483 744.481 224.5L744.227 225.45C736.186 256.056 762.854 282.635 793.328 279.495C852.424 268.628 837.121 186.259 782.256 192.654Z"
    />
    <path
      transform="scale(0.284417 0.284417)"
      d="M124.015 383.897L883.056 383.909C892.69 383.913 902.667 383.379 912.25 384.13C958.939 387.786 998.341 429.724 1000.76 476.197L1000.92 864.799C1000.76 869.122 1000.37 873.764 998.42 877.68L996.463 881.5C989.911 892.401 980.427 895.97 968.572 895.997L899.25 896.011L77.0587 896.022C67.8661 896.014 58.056 896.693 48.9211 895.854C35.0072 894.577 23.5793 879.889 23.375 866.468L23.4369 487.183C23.5294 435.824 55.2625 391.44 108.378 384.457C113.541 383.778 118.816 383.911 124.015 383.897ZM380.106 448.238C361.538 450.303 342.954 463.96 342.326 484L342.342 831.976L642.738 831.966L681.314 832.004L681.504 495.693C681.498 491.317 681.775 486.71 681.159 482.374C677.79 458.645 657.237 448.06 635.484 448.04L380.106 448.238ZM738.204 448.158C745.342 468.487 744.995 481.081 744.995 502.276L744.929 803.261C744.926 806.484 744.426 831.126 745.188 831.978L936.891 832.053L937.212 497.576C937.204 491.892 937.452 485.948 936.706 480.306C934.474 463.431 919.083 449.424 902.167 448.248C894.658 447.725 886.934 448.055 879.405 448.049L738.204 448.158ZM123.007 448.237C79.8098 452.385 86.8797 493.225 86.883 523.7L86.9418 831.966L249.75 831.967L278.56 831.989L278.604 517.75C278.609 495.06 276.032 469.799 285.462 448.442L123.007 448.237Z"
    />
  </svg>
);
