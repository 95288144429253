import React, { useMemo } from 'react';
import { useAuth } from '../../Auth.router';
import { Form } from 'react-final-form';
import InputField from 'components/InputField';
import PasswordField from 'components/PasswordField';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { Link } from 'react-router-dom';

import './Login.styles.scss';
import './Login.styles.responsive.scss';

const Login: React.FC = () => {
  const { isAuthInProgress, login } = useAuth();

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      email: t('General.email'),
      login: t('General.signin'),
      password: t('General.password'),
      invalidLogin: t('General.wrongLogin'),
      forgotPassword: t('Auth.forgotPassword'),
    }),
    [t],
  );

  return (
    <Form
      onSubmit={login}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        const submitDisabled = invalid || isAuthInProgress;

        return (
          <form className="login" onSubmit={handleSubmit}>
            <InputField
              type="email"
              name="username"
              label={messages.email}
              placeholder={messages.email}
              validate={formValidators.required(messages.forgotPassword)}
            />

            <PasswordField
              name="password"
              label={messages.password}
              placeholder={messages.password}
              validate={formValidators.required(messages.invalidLogin)}
            />

            <Link className="login__auth-link" to="/auth/forgot-password">
              {messages.forgotPassword}
            </Link>
            <Button
              className="login__submit"
              type="submit"
              variant="solid"
              disabled={submitDisabled}
            >
              {messages.login}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default Login;
