import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import FileInputField from 'components/FileInputField';
import useDropzoneProps from './hooks/useDropzoneProps';
import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';
import getFileUploadState, {
  mappedSubmitErrors,
} from '../../hooks/getFileUploadState';
import { Trans, useTranslation } from 'react-i18next';
import AddIcon from 'icons/Add.icon';
import formValidators from 'utils/formValidators';
import { uniqueId } from 'lodash';

import './Dropzone.styles.scss';
import './Dropzone.styles.responsive.scss';

const { required } = formValidators;

type DropzoneProps = {
  className?: string;
  fileType?: string;
};

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const { className, fileType = '.xlsx' } = props;

  const {
    values: { attachment },
    submitError,
  } = useFormState();

  const form = useForm();

  const formApi = useRef<FormApi>(form);

  const { getRootProps, getInputProps } = useDropzoneProps(fileType, formApi);

  const uploadedFileInfo = attachment?.map((file: any) => `${file.path}`);

  const {
    errors: { dropzoneError },
  } = getFileUploadState();

  const classes = classNames('dropzone', className, {
    'dropzone--error': dropzoneError,
    'dropzone--uploaded': attachment,
  });

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      uploadAnotherFile: t('UserDashboard.FileUpload.uploadAnotherFile'),
      uploadedFile: t('UserDashboard.FileUpload.uploadedFile'),
      prompt: t('UserDashboard.FileUpload.prompt'),
      help: t('UserDashboard.FileUpload.help'),
      required: t('required'),
    }),
    [t],
  );

  const fieldKeyRef = useRef<string>(uniqueId());

  return (
    <FileInputField
      key={fieldKeyRef.current}
      inputProps={getInputProps()}
      name="attachment"
      containerClassName={classes}
      maxFiles={1}
      validate={required(messages.required)}
      required
      accept={fileType}
      trigger={
        <div
          className="dropzone__trigger"
          {...getRootProps()}
          onClick={() => {
            fieldKeyRef.current = uniqueId();
          }}
        >
          {attachment ? (
            <div>
              <Trans
                i18nKey={messages.uploadedFile}
                values={{ uploadedFile: uploadedFileInfo }}
              />
            </div>
          ) : (
            <>
              <div>
                <Trans i18nKey={messages.prompt} />
              </div>
              <AddIcon />
              {dropzoneError ? (
                <div className="dropzone__submit-error">
                  {
                    mappedSubmitErrors[
                      submitError as keyof typeof mappedSubmitErrors
                    ]
                  }
                  &nbsp;
                  <span>{messages.uploadAnotherFile}</span>
                </div>
              ) : (
                <div className="dropzone__help-message">{messages.help}</div>
              )}
            </>
          )}
        </div>
      }
    />
  );
};

export default Dropzone;
