import { createContext } from 'react';

type StepContextProps = {
  currentStep: number;
  lastCompleteStep: number;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  resetForm: () => void;
};

export default createContext<Partial<StepContextProps>>({});
