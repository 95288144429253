import api from 'api';
import usePaginatedItems from 'hooks/usePaginatedItems';
import { BaseParams } from 'models/Pagination';
import { GroupFormBody, Group, User, GroupSubmitFormBody } from 'models/User';
import confirm from 'modules/confirm';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import utils from 'utils';
import debounce from 'lodash/debounce';

type HookProps = {
  modalRef: React.MutableRefObject<OverlayRef>;
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
};

export default function useManageGroupsHandlers(props: HookProps) {
  const { modalRef, setGroupId } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [associates, setAssociates] = useState<Array<User>>([]);
  const [associatesParams, setAssociatesParams] = useState<BaseParams>({
    limit: 100,
    page: 1,
  });

  const {
    items: groups,
    loading: fetching,
    changeParams,
    pagination,
    refresh,
  } = usePaginatedItems(api.admins.groups.getGroups);

  const changeAssociateParams = useCallback(
    debounce((search: string) => {
      setAssociatesParams({ ...associatesParams, search });
    }, 500),
    [],
  );

  useEffect(() => {
    const fetchAssociates = async () => {
      try {
        const { data } = await api.admins.associates.getAssociates(
          associatesParams,
        );
        const { items } = data;

        setAssociates(items);
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    };

    fetchAssociates();
  }, [associatesParams]);

  const deleteGroup = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        await api.admins.groups.deleteGroup(id);

        toast.success(t('Groups.deletedGroup'));
      } catch (error) {
        toast.error(t('General.error'));
      } finally {
        setLoading(false);
        refresh();
      }
    },
    [t],
  );

  const handleDeleteGroup = useCallback(
    async (values: Partial<Group>) => {
      const confirmDeletion = await confirm({
        title: t('Groups.deleteGroupTitle'),
        confirmBtnText: t('Delete'),
        content: t('Groups.deleteGroup', {
          name: values?.name,
        }),
      });

      if (confirmDeletion) {
        deleteGroup(values?.id);
      }
    },
    [deleteGroup, t],
  );

  const addGroup = useCallback(
    async (values: GroupSubmitFormBody) => {
      try {
        setLoading(true);

        await api.admins.groups.addGroup(values);

        toast.success(t('successAddingAssociate'));
        modalRef.current.close();

        setGroupId('');
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      } finally {
        setLoading(false);
      }
    },
    [t],
  );

  const updateGroup = useCallback(
    async (values: Partial<GroupSubmitFormBody>, id: string) => {
      try {
        setLoading(true);
        await api.admins.groups.updateGroup(values, id);

        toast.success(t('successUpdatingAssociate'));
        modalRef.current.close();

        setGroupId('');
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      } finally {
        setLoading(false);
      }
    },
    [t],
  );

  const onSubmit = useCallback(
    async (values: GroupFormBody, id?: string) => {
      const { userInfo, selectedTemplates, selectedUsers, ...rest } = values;

      const normalizedValues: GroupSubmitFormBody = {
        ...rest,
        userIds: userInfo,
      };

      if (id) {
        updateGroup(normalizedValues, id);
      } else {
        addGroup(normalizedValues);
      }
    },
    [addGroup, updateGroup],
  );

  const normalizedGroups = groups?.map(({ templates, ...rest }) => {
    const mappedTemplates =
      templates?.length > 2
        ? `${templates
            .slice(0, 2)
            .map((template: string) => t(`Groups.Templates.${template}`) || '')
            .join(', ')}...`
        : templates
            .map((template: string) => t(`Groups.Templates.${template}`) || '')
            .join(', ');

    return {
      ...rest,
      templates: mappedTemplates,
    };
  });

  return {
    onSubmit,
    handleDeleteGroup,
    associates,
    groups: normalizedGroups,
    fetching,
    loading,
    changeParams,
    pagination,
    changeAssociateParams,
  };
}
