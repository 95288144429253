import React, { useCallback } from 'react';
import classNames from 'classnames';
import { FieldRenderProps, useField, useForm } from 'react-final-form';
import { Select } from 'ncoded-component-library';
import type { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './MultipleSelectField.styles.scss';

export type selectedOption = {
  value: string;
  label: string;
};

type MultipleSelectFieldComponentProps = FieldRenderProps<
  (string | number)[],
  HTMLElement
> & {
  options: OptionValue<string | number>[];
  hideTags?: boolean;
  className?: string;
  selectedOptionsName?: string;
  onChange?: (value: (string | number)[]) => void;
};

const MultipleSelectFieldComponent: React.FC<
  MultipleSelectFieldComponentProps
> = (props) => {
  const {
    className,
    options,
    label,
    icon,
    selectedOptionsName,
    onChange: propOnChange,
    hideTags = false,
    input: { onChange: inputOnChange, value, name },
    ...rest
  } = props;

  const classes = classNames('sme-multiple-select-field', className);

  const { change } = useForm();

  const {
    input: { value: selectedOptions },
  } = useField<selectedOption[]>(selectedOptionsName);

  console.log(selectedOptionsName);

  const changeSelectedOptions = useCallback(
    (values: (string | number)[]) => {
      const newSelectedOptions = values
        .filter((value) => !selectedOptions.some((op) => op.value === value))
        .map((value) => options.find((option) => option.value === value));

      const removeDeselected = selectedOptions.filter((item) =>
        values.includes(item.value),
      );

      change(selectedOptionsName, [...removeDeselected, ...newSelectedOptions]);
    },
    [selectedOptions, options, change, selectedOptionsName],
  );

  const onChange = useCallback(
    (values: (string | number)[]) => {
      changeSelectedOptions(values);
      inputOnChange(values);
      propOnChange?.(values);
    },
    [inputOnChange, propOnChange, changeSelectedOptions],
  );

  const handleTagClick = useCallback(
    (selected: selectedOption) => {
      onChange(value.filter((val) => val !== selected.value));
    },
    [value, onChange],
  );

  return (
    <div className={classes}>
      {label && <label htmlFor={name}>{label}</label>}

      <Select
        searchable
        multiple
        options={options}
        value={value}
        onChange={onChange}
        icon={icon}
        {...rest}
      />

      {selectedOptions.length > 0 && value && (
        <div className="sme-multiple-select-field__selected">
          {selectedOptions.map((selected, index) => (
            <span key={selected.value} onClick={() => handleTagClick(selected)}>
              {selected.label}
              {index !== selectedOptions.length - 1 ? ',' : ''}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultipleSelectFieldComponent;
