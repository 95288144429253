import { useCallback, useEffect, useState } from 'react';
import { Group } from 'models/User';
import api from 'api';
import { toast } from 'react-toastify';
import utils from 'utils';

const useGroup = (
  id: string,
  admin = false,
): {
  group?: Group;
  loading?: boolean;
} => {
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState<Group>(null);

  const getGroup = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        const { data } = await api.admins.groups.getGroup(id);
        setGroup(data);
      }
    } catch (err) {
      toast.error(utils.handleResponseError(err));
    } finally {
      setLoading(false);
    }
  }, [admin, id]);

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  return {
    group,
    loading,
  };
};

export default useGroup;
