import React, { useMemo, useRef, useState } from 'react';
import Table from 'components/Table';
import { useTranslation } from 'react-i18next';
import Modal, { ModalRef } from 'components/Modal';
import ManageUsersForm from './components/ManageUsersForm';
import useManageUsersHandlers from './hooks/useManageUsersHandlers';

import './ManageUsers.styles.scss';

const ManageUsers: React.FC = () => {
  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>();

  const [associateId, setAssociateId] = useState<string>('');

  const {
    onSubmit,
    handleDeleteAssociate,
    normalizedUsers,
    fetching,
    loading,
    groupNames,
    changeParams,
    pagination,
    changeGroupsParams,
  } = useManageUsersHandlers({ modalRef, setAssociateId });

  const labels = useMemo(
    () => [
      { label: t('General.firstName'), key: 'firstName' },
      { label: t('General.lastName'), key: 'lastName' },
      { label: t('General.associateId'), key: 'associateId' },
      { label: t('General.position'), key: 'position' },
      { label: t('Groups.groups'), key: 'groups' },
    ],
    [t],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        className="manage-users-modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-associate"
        onClose={() => {
          setAssociateId('');
          changeGroupsParams('');
        }}
        onX={() => {
          setAssociateId('');
          changeGroupsParams('');
          modalRef.current.close();
        }}
        title={associateId ? t('editAssociate') : t('newAssociate')}
      >
        <ManageUsersForm
          inProgress={loading}
          onSubmit={(values) => onSubmit(values, associateId)}
          handleCancel={() => {
            setAssociateId('');
            modalRef.current.close();
          }}
          associateId={associateId}
          groupNames={groupNames}
          changeGroupsParams={changeGroupsParams}
        />
      </Modal>
      <Table
        fetching={fetching}
        title={t('associates')}
        labels={labels.map(({ label }) => label)}
        elementProps={labels.map(({ key }) => key)}
        contentArray={normalizedUsers}
        buttonText={t('addAssociate')}
        onDelete={(user) => handleDeleteAssociate(user)}
        onEdit={(id) => {
          setAssociateId(id);
          modalRef.current.open();
        }}
        onAddButtonClick={() => modalRef.current.open()}
        withActionButtons
        changeParams={changeParams}
        pagination={pagination}
      />
    </>
  );
};

export default ManageUsers;
