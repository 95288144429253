import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

import './EmailSent.styles.scss';
import './EmailSent.styles.responsive.scss';
import StepFormContext from 'providers/StepForm/StepForm.context';
import { useForm } from 'react-final-form';

type EmailSentProps = {
  className?: string;
};

const EmailSent: React.FC<EmailSentProps> = (props) => {
  const { className } = props;

  const classes = classNames('email-sent', className);

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      layoutHeader: t('UserDashboard.EmailSend.layoutHeader'),
      layoutText: t('UserDashboard.EmailSend.layoutText'),
      thankYouText: t('UserDashboard.EmailSend.thankYouText'),
      sendAnother: t('UserDashboard.EmailSend.sendAnother'),
    }),
    [t],
  );

  const form = useForm();

  const { resetForm } = useContext(StepFormContext);

  const formReset = () => {
    form.reset();

    resetForm();
  };

  return (
    <div className={classes}>
      <div className="email-sent__header">
        <h2>{messages.layoutHeader}</h2>
        <div>{messages.layoutText}</div>
      </div>
      <div className="email-sent__body">{messages.thankYouText}</div>
      <div className="email-sent__form-navigation">
        <Button onClick={formReset}>{messages.sendAnother}</Button>
      </div>
    </div>
  );
};

export default EmailSent;
