import React, { useContext } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AdminIcon } from 'icons/admin.svg';
import UserIcon from 'icons/User.icon';
import UsersIcon from 'icons/Users.icon';
import EmailsIcon from 'icons/Emails.icon';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import HistoryIcon from 'icons/History.icon';

export default () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);

  const adminItems = useMemo(() => {
    const commonItems = [
      {
        path: '/dashboard/manage-users?limit=20&page=1',
        label: (
          <>
            <span>
              <UserIcon />
            </span>
            {t('associates')}
          </>
        ),
      },
      {
        path: '/dashboard/manage-admins?limit=20&page=1',
        label: (
          <>
            <span>
              <AdminIcon />
            </span>
            {t('admins')}
          </>
        ),
      },
      {
        path: '/dashboard/manage-groups?limit=20&page=1',
        label: (
          <>
            <span>
              <UsersIcon />
            </span>
            {t('Groups.groups')}
          </>
        ),
      },
    ];

    return commonItems;
  }, [t]);

  const sideBarItems = useMemo(() => {
    const commonItems = [
      {
        path: '/user-dashboard',
        label: (
          <>
            <span>
              <EmailsIcon />
            </span>
            {t('sendEmails')}
          </>
        ),
      },
      {
        path: '/dashboard/history?limit=20&page=1',
        label: (
          <>
            <span>
              <HistoryIcon />
            </span>
            {t('history')}
          </>
        ),
      },
    ];

    return currentUser?.roles.includes('Admin')
      ? [...adminItems, ...commonItems]
      : commonItems;
  }, [adminItems, currentUser?.roles, t]);

  return sideBarItems;
};
