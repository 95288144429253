import React from 'react';
import classNames from 'classnames';
import './PreviewTable.styles.scss';
import './PreviewTable.styles.responsive.scss';

type PreviewTableProps = {
  className?: string;
  tableData: any;
  skipFirstColumn?: boolean;
};

const PreviewTable: React.FC<PreviewTableProps> = (props) => {
  const { className, tableData, skipFirstColumn } = props;

  const classes = classNames('preview-table', className);

  return (
    <table className={classes}>
      <thead>
        <tr>
          {tableData[0].map((key: any) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {tableData.slice(1).map((individualfileData: any, index: number) => (
          <tr key={index}>
            {skipFirstColumn && <td key={0} />}
            {individualfileData.map((value: any, index: number) => (
              <td key={index}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PreviewTable;
