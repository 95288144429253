import { AuthBody } from '../../models/AuthBody';
import publicHTTPClient from 'api/publicHttpClient';
import {
  ForgotPasswordValue,
  LoginValue,
  ResetPasswordSubmitValue,
} from 'types/form.types';

const authPath = (routePath: TemplateStringsArray) => `auth/${routePath}`;

function login(userData: LoginValue) {
  return publicHTTPClient.post<AuthBody>(authPath`login`, userData);
}

const refreshToken = (refreshToken: string) => {
  return publicHTTPClient.post(authPath`refresh-token`, { refreshToken });
};

function forgotPassword(userData: ForgotPasswordValue) {
  return publicHTTPClient.post<AuthBody>(authPath`forgot-password`, userData);
}

function resetPassword(userData: ResetPasswordSubmitValue) {
  return publicHTTPClient.post<AuthBody>(authPath`reset-password`, userData);
}

export default {
  login,
  refreshToken,
  forgotPassword,
  resetPassword,
};
