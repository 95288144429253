import React, { useCallback, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import api from 'api';
import { AxiosError } from 'axios';
import credentialsService from 'services/credentialsService';
import { toast } from 'react-toastify';
import {
  ForgotPasswordValue,
  LoginValue,
  ResetPasswordValue,
} from 'types/form.types';
import { useTranslation } from 'react-i18next';
import StaplesLogoIcon from 'icons/StaplesLogo.icon';

import './Auth.styles.scss';
import utils from 'utils';

export type OutletContext = {
  login: (values: LoginValue) => Promise<void>;
  forgotPassword: (values: ForgotPasswordValue) => Promise<void>;
  resetPassword: (values: ResetPasswordValue) => Promise<void>;
  isAuthInProgress: boolean;
};

const Auth: React.FC = () => {
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const handleLoginError = useCallback(
    (error: AxiosError) => {
      const { status } = error?.response || {};

      let errorMessage;

      switch (status) {
        case 404:
          errorMessage = t('Login.userNotFoundError');
          break;

        case 401:
          errorMessage = t('Login.invalid');
          break;

        default:
          errorMessage = t('General.error');

          break;
      }

      toast.error(errorMessage);
    },
    [t],
  );

  const login = useCallback(
    async (values: LoginValue) => {
      setIsAuthInProgress(true);
      const { username, password } = values;

      try {
        const {
          data: { token, refreshToken },
        } = await api.auth.login({
          username,
          password,
        });

        credentialsService.saveAuthBody({ token, refreshToken });

        navigate('/');
      } catch (err) {
        const e = err as AxiosError;
        handleLoginError(e);
      } finally {
        setIsAuthInProgress(false);
      }
    },
    [navigate, handleLoginError],
  );

  const forgotPassword = useCallback(async (values: ForgotPasswordValue) => {
    setIsAuthInProgress(true);

    try {
      await api.auth.forgotPassword(values);

      toast.success(t('Auth.ForgotPassword.requestSent'));
    } catch (err) {
      utils.toastError(err);
    } finally {
      setIsAuthInProgress(false);
    }
  }, []);

  const resetPassword = useCallback(
    async (values: ResetPasswordValue) => {
      setIsAuthInProgress(true);

      try {
        const submitValues = {
          password: values.password,
          resetCode: searchParams.get('resetCode') || '',
          resetToken: searchParams.get('resetToken') || '',
        };

        await api.auth.resetPassword(submitValues);

        toast.success(t('Auth.ResetPassword.requestSent'));

        navigate('/auth');
      } catch (err) {
        utils.toastError(err);
      } finally {
        setIsAuthInProgress(false);
      }
    },
    [searchParams],
  );

  return (
    <main className="sme-main">
      <div className="sme-main__auth-container">
        <a href="https://www.staples.ca/" target="_blank" rel="noreferrer">
          <StaplesLogoIcon className="sme-main__logo" />
        </a>
        <Outlet
          context={{
            login,
            isAuthInProgress,
            forgotPassword,
            resetPassword,
          }}
        />
      </div>
    </main>
  );
};

export default Auth;

export const useAuth = () => useOutletContext<OutletContext>();
