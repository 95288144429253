import React from 'react';
import { User } from 'models/User';
import { Button } from 'ncoded-component-library';
import EditIcon from 'icons/Edit.icon';
import DeleteIcon from 'icons/Delete.icon';
import classNames from 'classnames';

import './Row.styles.scss';

export type RowProps = {
  className?: string;
  el?: any;
  elementProps: string[];
  withActionButtons?: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (el: User) => Promise<void>;
};

const Row: React.FC<RowProps> = (props) => {
  const {
    className,
    el,
    elementProps,
    withActionButtons = false,
    onEdit,
    onDelete,
  } = props;

  return (
    <tr className={classNames('sme-row', className)}>
      {elementProps.map((name) => (
        <td key={name}>{el[name]}</td>
      ))}

      {withActionButtons && (
        <td className="sme-row__actions">
          <>
            <Button
              variant="icon"
              icon={<EditIcon />}
              className="sme-row__edit"
              onClick={() => onEdit(el.id)}
            />

            {onDelete && (
              <Button
                variant="icon"
                icon={<DeleteIcon />}
                className="sme-row__delete"
                onClick={() => onDelete(el)}
              />
            )}
          </>
        </td>
      )}
    </tr>
  );
};

export default Row;
