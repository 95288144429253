import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Field, Form, FormProps } from 'react-final-form';
import { SubmissionErrors } from 'final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import formValidators from 'utils/formValidators';
import { GroupFormBody, User } from 'models/User';
import { templatesOptions } from 'types/types';
import useGroup from './hooks/useGroup';
import MultipleSelectField from 'components/MultipleSelectField';
import { DebouncedFunc } from 'lodash';

import './ManageGroupsForm.styles.scss';

const { required } = formValidators;

type ManageGroupsFormProps = {
  className?: string;
  groupId?: string;
  inProgress: boolean;
  associates?: User[];
  handleCancel?: () => void;
  onSubmit: (
    values: GroupFormBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  changeAssociateParams?: DebouncedFunc<(searchString: string) => void>;
} & FormProps<GroupFormBody>;

const ManageGroupsForm: React.FC<ManageGroupsFormProps> = (props) => {
  const {
    className,
    groupId,
    inProgress,
    handleCancel,
    onSubmit,
    associates,
    changeAssociateParams,
  } = props;

  const { group, loading: loadingGroup } = useGroup(groupId);

  const classes = classNames('manage-groups-form', className);

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      groupName: t('Groups.groupName'),
      templates: t('Groups.allowedTemplates'),
      addAssociates: t('Groups.addAssociates'),
      searchByEmail: t('Groups.searchByEmail'),
      addGroup: t('Groups.addGroup'),
      updateGroup: t('Groups.updateGroup'),
      cancel: t('General.cancel'),
    }),
    [t],
  );

  const initialGroupValues: GroupFormBody = useMemo(
    () => ({
      name: group?.name,
      usersCount: group?.usersCount,
      templates: group?.templates,
      userInfo: group?.userInfo?.map((user) => user.id),
      selectedTemplates: group?.templates?.map((template) => ({
        value: template,
        label: t(`Groups.Templates.${template}`),
      })),
      selectedUsers: group?.userInfo.map((user) => ({
        value: user.id,
        label: user.info,
      })),
    }),
    [group],
  );

  const associateOptions = useMemo(
    () =>
      associates.map(({ firstName, lastName, email, id }) => ({
        label: `${firstName} ${lastName} - ${email}`,
        value: id,
      })),
    [associates],
  );

  const templates = useMemo(
    () =>
      Object.values(templatesOptions).map(({ value }) => ({
        value,
        label: t(`Groups.Templates.${value}`),
      })),
    [t],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={group ? initialGroupValues : {}}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, dirty } = formRenderProps;

        const submitDisabled = invalid || inProgress || !dirty;

        return (
          <>
            {(inProgress || loadingGroup) && <Loader color="#cc0000" />}
            <form className={classes} onSubmit={handleSubmit}>
              <InputField
                type="text"
                name="name"
                label={messages.groupName}
                required
                validate={required(t('required'))}
                placeholder={messages.groupName}
              />
              <Field
                component={MultipleSelectField}
                name="templates"
                label={messages.templates}
                placeholder={messages.templates}
                options={templates}
                selectedOptionsName="selectedTemplates"
              />
              <Field
                component={MultipleSelectField}
                name="userInfo"
                label={messages.addAssociates}
                placeholder={messages.searchByEmail}
                options={associateOptions}
                selectedOptionsName="selectedUsers"
                onSearchChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  changeAssociateParams(event?.target?.value || '')
                }
              />

              <div className="manage-groups-form__actions">
                <Button
                  type="button"
                  onClick={handleCancel}
                  variant="link"
                  className="manage-groups-form__actions__link"
                >
                  {messages.cancel}
                </Button>

                <Button
                  disabled={submitDisabled}
                  type="submit"
                  variant="solid"
                  className="manage-groups-form__actions__submit"
                >
                  {groupId ? messages.updateGroup : messages.addGroup}
                </Button>
              </div>
            </form>
          </>
        );
      }}
    />
  );
};

export default ManageGroupsForm;
