import React, { useMemo } from 'react';
import EmailsIcon from 'icons/Emails.icon';
import CheckmarkIcon from 'icons/Checkmark.icon';
import ExcelFileIcon from 'icons/ExcelFile.icon';
import { useTranslation } from 'react-i18next';

export default function () {
  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      title1: t('UserDashboard.FormSidebar.title1'),
      label1: t('UserDashboard.FormSidebar.label1'),
      title2: t('UserDashboard.FormSidebar.title2'),
      label2: t('UserDashboard.FormSidebar.label2'),
      title3: t('UserDashboard.FormSidebar.title3'),
      label3: t('UserDashboard.FormSidebar.label3'),
    }),
    [t],
  );

  const sideBarItems = useMemo(
    () => [
      {
        title: messages.title1,
        label: messages.label1,
        icon: <EmailsIcon />,
      },
      {
        title: messages.title2,
        label: messages.label2,
        icon: <ExcelFileIcon />,
      },
      {
        title: messages.title3,
        label: messages.label3,
        icon: <CheckmarkIcon />,
      },
    ],
    [t],
  );

  return { sideBarItems };
}
