import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { BaseParams, Pagination } from 'models/Pagination';
import { useTranslation } from 'react-i18next';

import './TablePagination.styles.scss';
import './TablePagination.styles.responsive.scss';

type TablePaginationProps = {
  className?: string;
  changeParams: (params: BaseParams) => void;
  pagination: Pagination;
  fetching?: boolean;
  searchString?: string;
  paginationSizeOptions?: number[];
};

const TablePagination: React.FC<TablePaginationProps> = (props) => {
  const {
    className,
    changeParams,
    pagination,
    fetching,
    searchString,
    paginationSizeOptions = [20, 50, 100],
  } = props;

  const { currentPage, pageSize, totalCount, totalPages } = pagination;

  const { t } = useTranslation();

  const classes = classNames('table-pagination', className);

  const changeParamsHandler = useCallback(
    (limit: number, page: number) => {
      changeParams({ limit, page, search: searchString });
    },
    [searchString],
  );

  return (
    <div className={classes}>
      <div className="table-pagination__page-size">
        {paginationSizeOptions?.map((paginationSize) => (
          <Button
            key={paginationSize}
            className="table-pagination__button--limit"
            variant="outline"
            disabled={pageSize === paginationSize}
            onClick={() => changeParamsHandler(paginationSize, 1)}
          >
            {paginationSize}
          </Button>
        ))}

        <div>{t('History.results')}</div>
      </div>

      <div className="table-pagination__page-number">
        <div>{`${1 + (currentPage - 1) * pageSize} - ${Math.min(
          currentPage * pageSize,
          totalCount,
        )} ${t('History.of')} ${totalCount}`}</div>
        <Button
          onClick={() => changeParamsHandler(pageSize, currentPage - 1)}
          disabled={currentPage === 1 || fetching}
          variant="outline"
        >
          {t('General.previous')}
        </Button>
        <Button
          onClick={() => changeParamsHandler(pageSize, currentPage + 1)}
          disabled={currentPage === totalPages || fetching}
        >
          {t('General.next')}
        </Button>
      </div>
    </div>
  );
};

export default TablePagination;
