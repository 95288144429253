import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { ModalRef } from 'components/Modal';
import Table from 'components/Table';
import useManageGroupsHandlers from './hooks/useManageGroupsHandlers';
import ManageGroupsForm from './components/ManageGroupsForm';

import './ManageGroups.styles.scss';
import './ManageGroups.styles.responsive.scss';

const ManageGroups: React.FC = () => {
  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>();

  const [groupId, setGroupId] = useState<string>('');

  const {
    onSubmit,
    handleDeleteGroup,
    associates,
    groups,
    fetching,
    loading,
    changeParams,
    pagination,
    changeAssociateParams,
  } = useManageGroupsHandlers({
    modalRef,
    setGroupId,
  });

  const labels = useMemo(
    () => [
      { label: t('Groups.groupName'), key: 'name' },
      { label: t('Groups.usersCount'), key: 'usersCount' },
      { label: t('Groups.allowedTemplates'), key: 'templates' },
    ],
    [t],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        className="manage-groups-modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-group"
        onClose={() => {
          setGroupId('');
          changeAssociateParams('');
        }}
        onX={() => {
          setGroupId('');
          changeAssociateParams('');
          modalRef.current.close();
        }}
        title={groupId ? t('Groups.editGroup') : t('Groups.newGroup')}
      >
        <ManageGroupsForm
          inProgress={loading}
          onSubmit={(values) => onSubmit(values, groupId)}
          handleCancel={() => {
            setGroupId('');
            modalRef.current.close();
          }}
          associates={associates}
          groupId={groupId}
          changeAssociateParams={changeAssociateParams}
        />
      </Modal>
      <Table
        fetching={fetching}
        title={t('Groups.groups')}
        labels={labels.map(({ label }) => label)}
        elementProps={labels.map(({ key }) => key)}
        contentArray={groups}
        buttonText={t('Groups.addGroup')}
        onAddButtonClick={() => modalRef.current.open()}
        withActionButtons
        changeParams={changeParams}
        pagination={pagination}
        onDelete={(group) => handleDeleteGroup(group)}
        onEdit={(id) => {
          setGroupId(id);
          modalRef.current.open();
        }}
      />
    </>
  );
};

export default ManageGroups;
