import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useForm, useFormState } from 'react-final-form';
import { Template } from 'models/Templates';
import { useTranslation } from 'react-i18next';
import useStyleWrapTemplates from '../../hooks/useStyleWrapTemplates';

import './TemplateCards.styles.scss';
import './TemplateCards.styles.responsive.scss';

type TemplateCardsProps = {
  className?: string;
};

const TemplateCards: React.FC<TemplateCardsProps> = (props) => {
  const { className } = props;

  const { change } = useForm();

  const {
    values: { templates: formTemplates, selectedTemplate },
  } = useFormState();

  const templates = useMemo(
    () => useStyleWrapTemplates(formTemplates),
    [formTemplates],
  );

  const areTemplatesLoaded = !!templates?.length;

  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      english: t('General.english'),
      french: t('General.french'),
    }),
    [t],
  );

  const changeSelectedTemplate = useCallback(
    (template: Template) => {
      if (selectedTemplate === template.name) {
        change('selectedTemplate', '');
      } else {
        change('selectedTemplate', template.name);
      }
    },
    [templates, selectedTemplate],
  );

  const classes = classNames('template-cards', className);

  return (
    <div className={classes}>
      {areTemplatesLoaded && (
        <>
          <div className="template-cards__list-container">
            {templates
              .filter((_, index) => index % 2 === 0)
              .map((template, templateCardIndex) => (
                <div
                  className={classNames('template-cards__card', {
                    'template-cards__card--active':
                      selectedTemplate === template.name ||
                      selectedTemplate ===
                        templates[templateCardIndex * 2 + 1].name,
                  })}
                  key={template.name}
                >
                  <h4>{template.name.split(' EN')[0]}</h4>
                  <div className="template-cards__languages">
                    <Button
                      className={classNames('template-cards__language-button', {
                        'template-cards__language-button--active':
                          selectedTemplate === template.name,
                      })}
                      variant="outline"
                      onClick={() => changeSelectedTemplate(template)}
                    >
                      {messages.english}
                    </Button>
                    <Button
                      className={classNames('template-cards__language-button', {
                        'template-cards__language-button--active':
                          selectedTemplate ===
                          templates[templateCardIndex * 2 + 1].name,
                      })}
                      variant="outline"
                      onClick={() =>
                        changeSelectedTemplate(
                          templates[templateCardIndex * 2 + 1],
                        )
                      }
                    >
                      {messages.french}
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TemplateCards;
