import React, { useContext } from 'react';
import classNames from 'classnames';
import StepFormContext from 'providers/StepForm/StepForm.context';
import useFormSidebarItems from './hooks/useFormSidebarItems';

import './FormSidebar.styles.scss';
import './FormSidebar.styles.responsive.scss';

type FormSidebarProps = {
  className?: string;
};

const FormSidebar: React.FC<FormSidebarProps> = (props) => {
  const { className } = props;

  const classes = classNames('form-sidebar', className);

  const { lastCompleteStep, currentStep } = useContext(StepFormContext);

  const { sideBarItems } = useFormSidebarItems();

  return (
    <aside className={classes}>
      {sideBarItems.map(({ title, label, icon }, index) => {
        return (
          <div
            key={label}
            className={classNames('form-sidebar__item', {
              'form-sidebar__item--completed':
                index < lastCompleteStep ||
                lastCompleteStep === sideBarItems.length - 1,
              'form-sidebar__item--current': index === currentStep,
            })}
          >
            <div className="form-sidebar__description">
              <h3>{title}</h3>
              <div>{label}</div>
            </div>
            {icon}
          </div>
        );
      })}
    </aside>
  );
};

export default FormSidebar;
